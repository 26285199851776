import { eventBus } from "@/eventbus";
import { Auth0Lock } from "auth0-lock";
import { navigateTo } from "@/utils/routing";

export default class AuthenticationService {
  constructor(router, store) {
    this.router = router;
    this.nextRoute = null;
    this.auth = new Auth0Lock("mUMx5x1bcjXUPT815w6LzOGjyn5nrjL4", "auth.platform.entwicklerheld.de", {
      configurationBaseUrl: "https://cdn.eu.auth0.com",
      auth: {
        redirectUrl: window.BASE_URL + "/callback?next=",
        audience: "https://polylith-io.eu.auth0.com/userinfo",
        responseType: "token id_token",
        params: { scope: "openid email profile" },
        redirect: false,
      },
      autoclose: false,
      language: "de",
      languageDictionary: {
        emailInputPlaceholder: "entwicklerheld@youremail.com",
        title: " ",
        signUpTitle: " ",
        signUpTerms: "",
      },
      theme: {
        logo: "https://s3.eu-central-1.amazonaws.com/polylith-public-files/logo-entwicklerheld-green.png",
        primaryColor: "rgb(52, 152, 219)",
      },
    });

    let next = "";

    this.auth.on("authenticated", (authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        eventBus.$emit("userLoggedIn");
        setTimeout(() => this.auth.hide(), 1500);

        if (this.nextRoute) {
          navigateTo(this.router, this.nextRoute.fullPath);
        }

        this.nextRoute = null;
      }
    });

    this.store = store;
  }

  parseJwt(token) {
    if (!token) {
      return {};
    }
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(window.atob(base64));
  }

  setSession(authResult) {
    let idTokenPayload = this.parseJwt(authResult.idToken);

    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expiresAt", JSON.stringify(idTokenPayload.exp));
    localStorage.setItem("id_token_payload", JSON.stringify(idTokenPayload));
    localStorage.setItem("roles", JSON.stringify(idTokenPayload["https://entwicklerheld.de/roles"]));
  }

  getAccessToken() {
    return localStorage.getItem("access_token");
  }

  getIDToken() {
    return localStorage.getItem("id_token");
  }

  getIDTokenPayload() {
    return JSON.parse(localStorage.getItem("id_token_payload"));
  }

  getExpireAt() {
    return JSON.parse(localStorage.getItem("expiresAt"));
  }

  login(next) {
    this.nextRoute = next;
    this.auth.show({
      allowSignUp: false,
    });
  }

  signUp(next) {
    this.auth.show({
      allowLogin: false,
    });
  }

  logout(nextPath = "/") {
    // Clear access token and ID token from local storage
    AuthenticationService.deleteLocalStorage();
    // We don't use vue router here, because its hard to handle the dynamic logout state in all components
    window.location.href = nextPath;
  }

  checkSession(result) {
    this.auth.checkSession({}, (err, authResult) => {
      result.authResult = authResult;
      result.error = err;

      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        eventBus.$emit("userLoggedIn");
        return result;
      }
      console.warn("Error during session renew", err);
    });

    return result;
  }

  static deleteLocalStorage() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("id_token_payload");
    localStorage.removeItem("expiresAt");
  }

  isAuthenticated() {
    let isAuthenticated = Date.now() < this.getExpireAt() * 1000;
    if (isAuthenticated === false) {
      AuthenticationService.deleteLocalStorage();
    }
    return isAuthenticated;
  }

  getAuthorizationHeader() {
    let headers = new Headers({ "Content-Type": "application/json" });
    if (this.getIDToken() !== null && this.getIDToken() !== "") {
      headers.append("Authorization", "Bearer " + this.getIDToken());
    }
    return headers;
  }

  isPayingCustomer() {
    let decodedToken = this.getIDTokenPayload();
    if (!decodedToken) {
      return false;
    }

    let companyState = decodedToken["https://entwicklerheld.de/companyState"];
    return companyState === "PAID_PLAN";
  }

  isFreeCustomer() {
    let decodedToken = this.getIDTokenPayload();
    if (!decodedToken) {
      return false;
    }

    let companyState = decodedToken["https://entwicklerheld.de/companyState"];
    return companyState === "FREE_PLAN";
  }

  isImportedCustomer() {
    let decodedToken = this.getIDTokenPayload();
    if (!decodedToken) {
      return false;
    }

    let companyState = decodedToken["https://entwicklerheld.de/companyState"];
    return companyState === "IMPORTED";
  }

  getUserID() {
    return this.getIDTokenPayload().sub;
  }
}
