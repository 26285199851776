export let companyProgressSteps = [
  {
    label: "Firmennamen eintragen",
    route: { name: "profile", anchor: "header" },
    isPremium: false,
    completionHandler: (profileData, isPayingCustomer) => {
      return profileData.profile.name;
    },
  },
  {
    label: "Website angeben",
    route: { name: "profile", anchor: "social" },
    isPremium: false,
    completionHandler: (profileData, isPayingCustomer) => {
      return profileData.socialMediaLinks.filter((socialMediaIcon) => socialMediaIcon.platform === "website").length;
    },
  },
  {
    label: "Standort eintragen",
    route: { name: "profile", anchor: "location" },
    isPremium: false,
    completionHandler: (profileData, isPayingCustomer) => {
      return profileData.locations.length;
    },
  },
  {
    label: "Firmenlogo hochladen",
    route: { name: "profile", anchor: "logo" },
    isPremium: false,
    completionHandler: (profileData, isPayingCustomer) => {
      return profileData.profile.logo;
    },
  },
  {
    label: "Slogan eintragen",
    route: { name: "profile", anchor: "slogan" },
    isPremium: false,
    completionHandler: (profileData, isPayingCustomer) => {
      return profileData.profile.slogan;
    },
  },
  {
    label: "Interaktive Stellenanzeige buchen",
    route: { name: "product-vacancy" },
    isPremium: false,
    completionHandler: (profileData, isPayingCustomer) => {
      if (isPayingCustomer) {
        return true;
      }
      return profileData.vacancyRequestSubmitted;
    },
  },
  {
    label: "Beschreibung eintragen",
    route: { name: "profile", anchor: "description" },
    isPremium: false,
    completionHandler: (profileData, isPayingCustomer) => {
      return profileData.profile.aboutUs;
    },
  },
  {
    label: "Technologien angeben",
    route: { name: "profile", anchor: "tech" },
    isPremium: false,
    completionHandler: (profileData, isPayingCustomer) => {
      return profileData.technologies.length;
    },
  },
  {
    label: "Social Media Accounts eintragen",
    route: { name: "profile", anchor: "social" },
    isPremium: false,
    completionHandler: (profileData, isPayingCustomer) => {
      let socialMediaLinks = profileData.socialMediaLinks.filter(
        (socialMediaIcon) => !(socialMediaIcon.platform === "website")
      );
      return socialMediaLinks.length;
    },
  },
  {
    label: "Hero Image hochladen",
    route: { name: "profile", anchor: "hero-img" },
    isPremium: false,
    completionHandler: (profileData, isPayingCustomer) => {
      return profileData.profile.heroImage;
    },
  },
  {
    label: "Bilder hochladen",
    route: { name: "profile", anchor: "photo-slider" },
    isPremium: false,
    completionHandler: (profileData, isPayingCustomer) => {
      if (profileData.profile.sliderImage1 && profileData.profile.sliderImage2 && profileData.profile.sliderImage3) {
        return true;
      }
      return false;
    },
  },
];


export const REMOTE_TYPE_HELP_TEXTS = {
  "REMOTE_PER_TEAM": "Remote Möglichkeiten sind von Team zu Team unterschiedlich.",
  "REMOTE_0": "Wir arbeiten ausschließlich in unseren Büros (keine Home-Office-Möglichkeit).",
  "REMOTE_25": "1-2 Tage pro Woche kann aus dem Home-Office gearbeitet werden.",
  "REMOTE_50": "Circa die Hälfte der Arbeitszeit kann aus dem Home-Office gearbeitet werden.",
  "REMOTE_75": "Mindestens 1 Tag pro Woche erwarten wir Präsenz in unseren Büroräumen.",
  "REMOTE_100": "Wir bieten die Möglichkeit, die komplette Arbeitszeit aus dem Homeoffice zu absolvieren.",
}
