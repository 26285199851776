<template>
  <div class="wrapper">
    <base-sidebar v-if="!$route.meta.sideBarHidden" ref="sidebar" />
    <div class="container-fluid">
      <div v-if="$route.name === 'register' || $route.name === 'register-with-code'" class="stripe">
        <img
          id="eh-logo"
          src="https://public-polylith.s3.eu-central-1.amazonaws.com/eh-logo-full.png"
          alt="EntwicklerHeld GmbH"
          :class="{ 'sidebar-present': !$route.meta.sideBarHidden }"
        />
        <svg
          preserveAspectRatio="none"
          data-name="Ebene 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 368 38.08"
          class="swinging-lines"
          style="position: absolute; bottom: -3px; left: -50px; "
        >
          <path
            class="cls-1"
            d="M0,108.56c7.29-3.1,23.2-8.93,50.2-9.25,15.65-.19,28.29,2.53,45.8,5.21,22.21,3.4,22.46,4.24,34.23,5.21,23.18,1.92,29-4.49,60.77-5.89,31.33-1.37,36.11,5,69,4,30.48-.93,35.28-5.38,72.83-5.88,20.69-.28,29.45.58,35.17,2.85V111H0Z"
            transform="translate(0 -73)"
          />
          <path
            class="cls-2"
            d="M368,100.53c-7.43-9.44-13.71-26.72-40.53-27.49-15.55-.45-31.79,3.34-49,11.68-21.84,10.57-30.37,10.47-42,13.54-22.89,6-27-6.55-58.69-10.57-31.18-4-34.54,11-67.25,8.2C80.2,93.29,75.16,79.71,37.85,78.45,17.29,77.75,5.54,81.8,0,88.8c0,0,.33,22.12,0,22.23s367,0,368,0Z"
            transform="translate(0 -73)"
          />
        </svg>
      </div>
      <div class="main" :class="{ fullscreen: isFullscreen }" @click="closeSide()">
        <div class="top-bar">
          <breadcrumbs class="breacrumbs" />
        </div>
        <slot></slot>
      </div>
      <SideBarToggleButton
        v-if="!$route.meta.sideBarHidden"
        id="side-bar-toggle-button"
        class="sidebar-toggle-button pull-left"
      />
    </div>
  </div>
</template>

<script>
import BaseSidebar from "./BaseSidebar";
import Breadcrumbs from "./Breadcrumbs";
import SideBarToggleButton from "@/components/base/SideBarToggleButton";

export default {
  components: {
    SideBarToggleButton,
    BaseSidebar,
    Breadcrumbs,
  },
  computed: {
    isFullscreen() {
      if (this.$route.name === "register") {
        return true;
      }
      return false;
    },
  },
  methods: {
    closeSide: function() {
      if (this.$refs.sidebar) {
        this.$refs.sidebar.closeSidebar();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;

  .container-fluid {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    margin-left: -0.7rem;
    background-color: #fafafa;
  }

  .main {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    overflow: hidden;
  }

  .sidebar-toggle-button {
    position: absolute;
    top: 25px;
    left: 15px;
    margin-right: 1rem;
    z-index: 7;
  }

  .breacrumbs {
    padding-top: 0.3rem;
  }
}

@media (max-width: 769px) {
  .wrapper {
    .container-fluid {
      margin-left: 0;
    }

    .main.fullscreen {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;
    }
  }
}

.stripe {
  position: absolute;
  height: 400px;
  width: 100%;
  background-color: #52d3aa;
  background-image: -webkit-gradient(linear, 0% 0%, 100% 100%, color-stop(0, #3f95ea), color-stop(1, #52d3aa));
  background-image: -webkit-repeating-linear-gradient(top left, #3f95ea 0%, #52d3aa 100%);
  background-image: repeating-linear-gradient(to bottom right, #3f95ea 0%, #52d3aa 100%);
  background-image: -ms-repeating-linear-gradient(top left, #3f95ea 0%, #52d3aa 100%);
  padding: 1rem;
  overflow: hidden;
}
@media (max-width: 769px) {
  .stripe {
    height: 100px;
  }
}

.cls-1,
.cls-2 {
  fill: #f5f4f7;
}

.cls-2 {
  opacity: 0.06;
}

@media (max-width: 768px) {
  .top-bar {
    z-index: 6;
    position: relative;
  }
}

#eh-logo {
  max-width: 350px;
  width: 80%;
  margin: 0.5rem auto;
  display: block;
}

.swinging-lines {
  width: 130%;
}

@media (max-width: 769px) {
  .swinging-lines {
    display: none;
  }
}

@media (max-width: 450px) {
  #eh-logo.sidebar-present {
    margin-left: 4rem;
    margin-top: 0.7rem;
  }
}

#side-bar-toggle-button {
  display: block;
}

@media (min-width: 769px) {
  #side-bar-toggle-button {
    display: none;
  }
}
</style>
