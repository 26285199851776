export class FlowResult {
  constructor() {
    this.error = null;
    this.flow_data = {};
    this.loaded = false;
  }
}

export class TestStatusResult {
  constructor() {
    this.error = null;
    this.apiData = {};
    this.loaded = false;
  }
}
