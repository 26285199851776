import { UserServiceNetwork } from "./UserNetworkService";
import { UserServiceStub } from "./UserStubService";

export default class UserService {
  constructor(auth) {
    this.authService = auth;
  }

  getUserProfile() {
    if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
      console.info("window.USER_API_URL is not set! Using Stub data!");
      return new UserServiceStub(this.authService).getUserProfile();
    }
    return new UserServiceNetwork(this.authService).getUserProfile();
  }

  changeUserProfile(data) {
    if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
      console.info("window.USER_API_URL is not set! Using Stub data!");
      return new UserServiceStub(this.authService).changeUserProfile(data);
    }
    return new UserServiceNetwork(this.authService).changeUserProfile(data);
  }

  getProfileDataOfMultipleUsers(user_ids) {
    if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
      console.info("window.USER_API_URL is not set! Using Stub data!");
      return new UserServiceStub(this.authService).getProfileDataOfMultipleUsers(user_ids);
    }
    return new UserServiceNetwork(this.authService).getProfileDataOfMultipleUsers(user_ids);
  }

  postUserRegistration(data) {
    if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
      console.info("window.USER_API_URL is not set! Using Stub data!");
      return new UserServiceStub(this.authService).postUserRegistration(data);
    }
    return new UserServiceNetwork(this.authService).postUserRegistration(data);
  }

  applyForBeingACreator(creatorInterest) {
    if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
      console.info("window.USER_API_URL is not set! Using Stub data!");
      return new UserServiceStub(this.authService).applyForBeingACreator(creatorInterest);
    }
    return new UserServiceNetwork(this.authService).applyForBeingACreator(creatorInterest);
  }

  getCoinsLeaderBoard() {
    if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
      console.info("window.USER_API_URL is not set! Using Stub data!");
      return new UserServiceStub(this.authService).getCoinsLeaderBoard();
    }
    return new UserServiceNetwork(this.authService).getCoinsLeaderBoard();
  }

  getPublicUserProfile(userId) {
    if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
      console.info("window.USER_API_URL is not set! Using Stub data!");
      return new UserServiceStub(this.authService).getPublicUserProfile(userId);
    }
    return new UserServiceNetwork(this.authService).getPublicUserProfile(userId);
  }

  getDataForPublicUserIds(userIds) {
    if (typeof window.USER_API_URL === "undefined" || window.USER_API_URL === "") {
      console.info("window.USER_API_URL is not set! Using Stub data!");
      return new UserServiceStub(this.authService).getDataForPublicUserIds(userIds);
    }
    return new UserServiceNetwork(this.authService).getDataForPublicUserIds(userIds);
  }
}
