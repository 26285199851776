import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import Fragment from "vue-fragment";
import VueTextAreaAutosize from "vue-textarea-autosize";
import VueGooglePlaces from "vue-google-places";
import * as VueGoogleMaps from "vue2-google-maps";
import Notifications from "vue-notification";
import Vuelidate from "vuelidate";
import VueGtag from "vue-gtag";

import AuthenticationService from "./services/Authentication";
import CompanyService from "./services/company/CompanyService";
import FlowService from "./services/flow/FlowService";
import MessageService from "./services/message/MessageService";
import QualityService from "./services/quality/QualityService";
import ResumeService from "./services/resume/ResumeService";
import TaskManagementService from "./services/task-management/TaskManagementService";
import NotificationsService from "./services/notifications/NotificationsService";
import InteractsService from "./services/interact/InteractsService";
import UserService from "@/services/user/UserService";
import GoogleAnalyticsService from "./services/tracking/GoogleAnalytics";
import "font-awesome/css/font-awesome.min.css";
import { BootstrapVue } from "bootstrap-vue";
import VueYoutube from "vue-youtube";
import CookieConsentService from "@/services/cookie-consent/CookieConsentService";
import { getCookieConsent, isCookieConsentSet } from "@/services/Utilities";
import "@/assets/devfont.css";

Vue.config.productionTip = false;
if (isCookieConsentSet() && getCookieConsent()) {
  if (window.GOOGLE_ANALYTICS_ID) {
    Vue.use(
      VueGtag,
      {
        config: {
          id: window.GOOGLE_ANALYTICS_ID,
          params: { anonymize_ip: true },
        },
      },
      router
    );
  }
}
export const services = new Vue({
  data() {
    const authenticationService = new AuthenticationService(router, store);
    const companyService = new CompanyService(authenticationService);
    const flowService = new FlowService(authenticationService);
    const messageService = new MessageService(authenticationService);
    const qualityService = new QualityService(authenticationService);
    const resumeService = new ResumeService(authenticationService);
    const taskManagementService = new TaskManagementService(authenticationService);
    const interactsService = new InteractsService(authenticationService);
    const userService = new UserService(authenticationService);
    const notificationsService = new NotificationsService(authenticationService);
    const cookieConsentService = new CookieConsentService(authenticationService);

    const googleAnalyticsService = new GoogleAnalyticsService(Vue.$gtag);

    return {
      authenticationService,
      companyService,
      qualityService,
      messageService,
      flowService,
      taskManagementService,
      resumeService,
      interactsService,
      userService,
      notificationsService,
      googleAnalyticsService,
      cookieConsentService,
    };
  },
});

Vue.use(Fragment.Plugin);
Vue.use(VueTextAreaAutosize);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCVWu7dN6ToxD8ksp17hJ9DOtVkN19jbxY",
    libraries: "places",
  },
});
Vue.use(VueGooglePlaces);
Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(VueYoutube);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
