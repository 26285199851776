import Vue from "vue";
import Router from "vue-router";

import { REGISTRATION_CODE_NAME } from "@/utils/registrationCode";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "welcome",
    },
    {
      path: "/challenge",
      name: "challenges",
      component: () =>
        import(
          /* webpackPrefetch: true, webpackChunkName: "Challenges", webpackPrefetch: true */ "./views/Challenges.vue"
        ),
      meta: {
        breadcrumb: [{ name: "Challenge Liste" }],
      },
    },
    {
      path: "/challenge/:sponsored/:id",
      name: "challenge-details",
      component: () =>
        import(
          /* webpackPrefetch: true, webpackChunkName: "ChallengeDetails", webpackPrefetch: true */ "./views/ChallengeDetails.vue"
        ),
      props: true,
      meta: {
        breadcrumb: [{ name: "Challenge Liste", link: "/challenge" }, { name: "Challenge Übersicht" }],
      },
    },
    {
      path: "/challenge/:sponsored/:id/:challengeId/report/:leadId",
      name: "challenge-report",
      component: () =>
        import(/* webpackPrefetch: true, webpackChunkName: "Report", webpackPrefetch: true */ "./views/Report.vue"),
      props: true,
      meta: {
        breadcrumb: [
          { name: "Challenge Liste", link: "/challenge" },
          { name: "Challenge Übersicht", link: "/challenge/:sponsored/:id" },
          { name: "Report" },
        ],
      },
    },
    {
      path: "/report/:acceptedChallengeUuid",
      name: "challenge-report-by-acceptance",
      component: () =>
        import(
          /* webpackPrefetch: true, webpackChunkName: "ReportByAcceptance", webpackPrefetch: true */ "./views/ReportByAcceptance.vue"
        ),
      props: true,
      meta: {
        sideBarHidden: true,
        breadcrumb: [],
      },
    },
    {
      path: "/callback",
      name: "callback",
      component: () =>
        import(/* webpackPrefetch: true, webpackChunkName: "Callback", webpackPrefetch: true */ "./views/Callback.vue"),
    },
    {
      path: "/register",
      name: "register",
      component: () =>
        import(/* webpackPrefetch: true, webpackChunkName: "Register", webpackPrefetch: true */ "./views/Register.vue"),
    },
    {
      path: "/register_with_code",
      name: "register-with-code",
      component: () =>
        import(
          /* webpackPrefetch: true, webpackChunkName: "RegisterWithCode", webpackPrefetch: true */ "./views/RegisterWithCode.vue"
        ),
    },
    {
      path: "/welcome",
      name: "welcome",
      component: () =>
        import(/* webpackPrefetch: true, webpackChunkName: "Welcome", webpackPrefetch: true */ "./views/Welcome.vue"),
      meta: {
        breadcrumb: [{ name: "" }],
      },
    },
    {
      path: "/channel/:channelId",
      name: "channel",
      props: true,
      component: () =>
        import(/* webpackPrefetch: true, webpackChunkName: "Channel", webpackPrefetch: true */ "./views/Channel.vue"),
    },
    {
      path: "/profile",
      name: "profile",
      component: () =>
        import(/* webpackPrefetch: true, webpackChunkName: "Profile", webpackPrefetch: true */ "./views/Profile.vue"),
      meta: {
        breadcrumb: [{ name: "Profil" }],
      },
    },
    {
      path: "/candidates",
      name: "candidatesdashboard",
      component: () =>
        import(
          /* webpackPrefetch: true, webpackChunkName: "CCandidatesDashboard", webpackPrefetch: true */ "./views/CCandidatesDashboard.vue"
        ),
      meta: {
        breadcrumb: [{ name: "Talents Dashboard" }],
      },
    },
    {
      path: "/resume/:accessId",
      name: "resume-preview",
      component: () =>
        import(/* webpackPrefetch: true, webpackChunkName: "Resume", webpackPrefetch: true */ "./views/Resume.vue"),
      props: true,
      meta: {
        sideBarHidden: true,
      },
    },
    {
      path: "/logout",
      name: "logout",
      component: () =>
        import(
          /* webpackPrefetch: true, webpackChunkName: "Logout", webpackPrefetch: true */ "./components/base/Logout.vue"
        ),
    },
    {
      path: "/error/missing_permission",
      name: "missingPermission",
      component: () =>
        import(
          /* webpackPrefetch: true, webpackChunkName: "MissingPermission", webpackPrefetch: true */ "./views/MissingPermission.vue"
        ),
    },
    {
      path: "/error",
      name: "error",
      component: () =>
        import(/* webpackPrefetch: true, webpackChunkName: "Error", webpackPrefetch: true */ "./views/Error.vue"),
    },
    {
      path: "/after_login_redirect",
      name: "afterLoginRedirect",
      component: () =>
        import(
          /* webpackPrefetch: true, webpackChunkName: "AfterLoginRedirect", webpackPrefetch: true */ "./views/AfterLoginRedirect.vue"
        ),
    },
  ],
});

export default router;

const QUERY_PARAMS_TO_KEEP = [REGISTRATION_CODE_NAME];
router.beforeEach((to, from, next) => {
  let foundMismatch = false;
  for (const query_param_name of QUERY_PARAMS_TO_KEEP) {
    if (!(query_param_name in from.query)) {
      continue;
    }
    if (query_param_name in to.query) {
      continue;
    }
    to.query[query_param_name] = from.query[query_param_name];
    foundMismatch = true;
  }

  if (foundMismatch) {
    next(to);
    return;
  }

  return next();
});
