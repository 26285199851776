<template>
  <div class="breadcrumbs">
    <ul class="crumbs">
      <li
        v-for="(breadcrumb, idx) in breadCrumbList"
        :key="idx"
        :class="{ linked: breadcrumb.link }"
        @click="routeTo(idx)"
      >
        {{ breadcrumb.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { navigateTo } from "@/utils/routing";

export default {
  name: "Breadcrumb",
  data() {
    return {
      breadCrumbList: [],
    };
  },
  watch: {
    $route() {
      this.updateList();
    },
  },
  mounted() {
    this.updateList();
  },
  methods: {
    routeTo(pRouteTo) {
      if (this.breadCrumbList[pRouteTo].link) {
        let baseUrl = this.breadCrumbList[pRouteTo].link;
        if (baseUrl.includes(":sponsored")) {
          baseUrl = baseUrl.replace(":sponsored", this.$route.params.sponsored);
        }
        if (baseUrl.includes(":id")) {
          baseUrl = baseUrl.replace(":id", this.$route.params.id);
        }
        navigateTo(this.$router, { path: baseUrl });
      }
    },
    updateList() {
      this.breadCrumbList = this.$route.meta.breadcrumb;
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  margin-top: 1.5rem;
  margin-bottom: 2.4rem;
}

.crumbs {
  display: flex;
  margin-left: 1rem;
  text-transform: uppercase;
  color: rgba(170, 173, 188, 0.5);
}

.crumbs li {
  z-index: 1;
}
@media (max-width: 845px) {
  .crumbs {
    margin-left: 0.5rem;
  }
}

@media (max-width: 768px) {
  .crumbs {
    margin-left: 3.5rem;
  }
}

.linked {
  color: $eh-green;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }

  &::after {
    content: ">>";
    color: black;
    margin: 0 0.4rem;
  }
}

/*TODO: find a better solution for the breadcrumbs on mobile devices*/
@media (max-width: 768px) {
  ul.crumbs li {
    display: none;
  }

  ul.crumbs li:last-child {
    display: list-item;
  }
}
</style>
