import { NotificationsServiceNetwork } from "./NotificationsServiceNetwork";
import { NotificationsServiceStub } from "./NotificationsServiceStub";

export default class NotificationsService {
  constructor(auth) {
    this.authService = auth;
  }

  getNewMessageNotificationSocket() {
    if (typeof window.NOTIFICATIONS_API_URL === "undefined" || window.NOTIFICATIONS_API_URL === "") {
      console.info("Using Stub data for NotificationsService!");
      return new NotificationsServiceStub(this.authService).getNewMessageNotificationSocket();
    }
    return new NotificationsServiceNetwork(this.authService).getNewMessageNotificationSocket();
  }
}
