import {
  GetChannelDetailsResult,
  GetChannelsResult,
  SendMessageResult,
  SaveDraftMessageResult,
  GetChannelIdResult,
  GetJobOfferIdsResult,
  JoinChannelForCompanyResult,
} from "./MessageServiceResponses";

export class MessageServiceStub {
  constructor(auth) {
    this.authService = auth;
  }

  getJobOfferIds(channelId) {
    let result = new GetJobOfferIdsResult();
    setTimeout(function() {
      if (channelId === "channel000") {
        result.apiData = ["17dd4756-db91-46bc-a2e5-cee777a0f3d0", "590c1e6a-4bcf-45c7-ad33-ef7b410cacbc"];
      } else if (channelId === "channel001") {
        result.apiData = ["79bc4821-de44-4cf3-b4cc-aa12dc65ba32"];
      } else if (channelId === "channel002") {
        result.apiData = ["3bbf2c27-f454-4f0b-9c98-874f06d30286"];
      } else if (channelId === "channel003") {
        result.apiData = ["ALL_WITHOUT_JOB_SELECTION"];
      }
      result.loaded = true;
    }, Math.ceil(Math.random() * 2000) + 1000);
    return result;
  }

  getChannels() {
    let result = new GetChannelsResult();
    let date1 = new Date();
    let date2 = new Date();
    date1.setMonth(date1.getMonth() - 10);
    date1.setDate(date1.getDate() - 5);
    date2.setMonth(date2.getMonth() - 3);
    setTimeout(function () {
      result.apiData = [
        {
          id: "channel001",
          name: "Secured secondary solution",
          lastMessageDatetime: "2023-07-06T13:49:29.569324Z",
          unreadMessage: true,
          draftMessage: false,
          totalMessagesSent: 5,
          totalMessagesOfPlatformUser: 2,
          unrespondedMessage: true,
          answeredMessage: true,
          members: [
            {
              id: "a33442ca-21a1-46b7-a52e-713a8ce018c0",
              name: "Philipp Dienstdienstdienstdienst",
              image: "../assets/img/dienst.jpg",
              companyName: "EntwicklerHeld",
              companyLogo: "../assets/img/eh-logo.png",
            },
          ],
          isEmployeeChannelMember: true,
          sources: {
            jobRequestIds: [
              "79bc4821-de44-4cf3-b4cc-aa12dc65ba32",
              "b8da09eb-0348-414c-aac7-b19acebdb90e",
              "8d1372fb-7441-4a26-8141-fa3969dac728",
            ],
            sponsoredLeadIds: ["cf18ed28-7373-46d0-8a50-6d74d7a2000b", "6fe29d6f-68c8-4132-8102-5eb66b6dcafd"],
          },
        },
        {
          id: "channel000",
          name: "Ameliorated 5thgeneration system engine",
          lastMessageDatetime: "2023-07-06T14:01:55.871085Z",
          unreadMessage: false,
          unrespondedMessage: true,
          draftMessage: false,
          totalMessagesSent: 3,
          totalMessagesOfPlatformUser: 0,
          answeredMessage: true,
          members: [
            {
              id: "member001",
              name: "Ilja Bauer",
              image: "../assets/img/cookie.png",
              companyName: "EntwicklerHeld",
              companyLogo: "../assets/img/eh-logo.png",
            },
          ],
          isEmployeeChannelMember: true,
          sources: {
            jobRequestIds: ["17dd4756-db91-46bc-a2e5-cee777a0f3d0", "590c1e6a-4bcf-45c7-ad33-ef7b410cacbc"],
            sponsoredLeadIds: [],
          },
        },
        {
          id: "channel002",
          name: "Ameliorated 5thgeneration system engine",
          lastMessageDatetime: date1.toISOString(),
          unreadMessage: false,
          unrespondedMessage: true,
          draftMessage: false,
          totalMessagesSent: 2,
          totalMessagesOfPlatformUser: 1,
          answeredMessage: true,
          members: [
            {
              id: "member002",
              name: "Felix Hanspach",
              image: "../assets/img/gavel.png",
              companyName: "polylith GbR",
              companyLogo: "../assets/img/eh-logo.png",
            },
            {
              id: "member002",
              name: "Norma Driske",
              image: "../assets/img/gavel.png",
              companyName: "",
              companyLogo: "",
            },
          ],
          isEmployeeChannelMember: false,
          sources: {
            jobRequestIds: ["3bbf2c27-f454-4f0b-9c98-874f06d30286"],
            sponsoredLeadIds: [],
          },
        },
        {
          id: "channel003",
          name: "Ameliorated 5thgeneration system engine",
          lastMessageDatetime: date2.toISOString(),
          unreadMessage: false,
          unrespondedMessage: true,
          draftMessage: true,
          totalMessagesSent: 0,
          totalMessagesOfPlatformUser: 0,
          answeredMessage: false,
          members: [
            {
              id: "member003",
              name: "Jakob Blume",
              image: "../assets/img/jakob+phil.png",
              companyName: "queo GmbH",
              companyLogo: "../assets/img/logo.png",
            },
          ],
          isEmployeeChannelMember: false,
          sources: {
            jobRequestIds: [],
            sponsoredLeadIds: [],
          },
        },
      ];
      result.loaded = true;
    }, Math.ceil(Math.random() * 2000) + 1000);
    return result;
  }

  getChannelDetails(channelId) {
    let result = new GetChannelDetailsResult();
    setTimeout(function() {
      result.apiData = {
        id: "424ab85f-1ccc-47b8-bd8c-fcf33f90ba03",
        name: "Versatile didactic contingency",
        members: [
          {
            id: "a33442ca-21a1-46b7-a52e-713a8ce018c0",
            name: "Philipp Dienst",
            image: "../../assets/jakob+phil.png",
            companyName: "",
            companyLogo: "",
          },
          {
            id: "a33442ca-21a1-46b7-a52e-713a8ce018c0",
            name: "Philipp",
            image: "../../assets/logo.png",
            companyName: "EntwicklerHeld",
            companyLogo: "../assets/img/eh-logo.png",
          },
        ],
        userIsChannelMember: true,
        messages: [
          {
            id: "message000",
            senderId: "a33442ca-21a1-46b7-a52e-713a8ce018c0",
            senderName: "Philipp Dienst",
            you: false,
            content: "Hey EntwicklerHeld, dein CV sieht super aus, willst du bei EntwicklerHeld arbeiten?",
            serverTimeReceived: "2020-09-25T07:58:42.168542Z",
            unRead: false,
          },
          {
            id: "message003",
            senderId: "5e07782b-a8b9-4d71-9d1c-54a4031fb228",
            senderName: "Robert Ali",
            you: true,
            content: "NÖÖÖ",
            serverTimeReceived: "2020-09-25T08:58:42.178628Z",
            unRead: false,
          },
          {
            id: "message002",
            senderId: "a33442ca-21a1-46b7-a52e-713a8ce018c0",
            senderName: "Philipp Dienst",
            you: false,
            content: "Warum denn nicht? Entwicklerheld ist toll!",
            serverTimeReceived: "2020-09-25T09:58:42.175791Z",
            unRead: false,
          },
          {
            id: "message001",
            senderId: "5e07782b-a8b9-4d71-9d1c-54a4031fb228",
            senderName: "Robert Ali",
            you: true,
            content: "Ich arbeite schon bei EntwicklerHeld und bin zufrieden!",
            serverTimeReceived: "2020-09-25T10:58:42.172409Z",
            unRead: true,
          },
          {
            id: "message004",
            senderId: "5e07782b-a8b9-4d71-9d1c-54a4031fb228",
            senderName: "Robert Ali",
            you: false,
            content: `[{
                "component":"WorkSamplesMessage",
                "static":{"technology":{"id":1,"name":"Spring Boot","language":"Java"},
                    "challenge":{
                        "image":"https://production-task-management-media-storage.s3.amazonaws.com/047__Programmer-Yellow.png",
                        "title":"The mighty oracle for 'CannotDoMuch#' programs",
                        "slug":"the-mighty-oracle-for-cannotdomuch-programs",
                        "id":"9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
                        "groupId":"6",
                        "technology":{"id":1,"name":"","language":"Java"}
                    }
                },
                "context":"shareWorkSample"}]`,
            serverTimeReceived: "2020-09-25T10:58:42.172409Z",
            unRead: true,
          },
        ],
        draftMessage: {
          id: "message004",
          content:
            "Security really deep begin focus impact partner. Course I fight produce after return television. Buy hospital most out majority.",
          serverTimeReceived: "2020-09-25T11:58:42.172409Z",
        },
        sources: {
          jobRequestIds: ["6fe29d6f-68c8-4132-8102-5eb66b6dcafd", "8d1372fb-7441-4a26-8141-fa3969dac728"],
          sponsoredLeadIds: ["cf18ed28-7373-46d0-8a50-6d74d7a2000b", "b8da09eb-0348-414c-aac7-b19acebdb90e"],
        },
      };

      // Set different messages for each channel
      if (channelId === "channel000" || channelId === "channel001") {
        result.apiData.messages = [
          {
            id: "message000",
            senderId: "a33442ca-21a1-46b7-a52e-713a8ce018c0",
            senderName: "Philipp Dienst",
            you: false,
            content: "Guten Tag! Wie gehts? 😁",
            serverTimeReceived: "2020-09-25T17:58:42.168542Z",
            unRead: false,
          },
          {
            id: "message003",
            senderId: "5e07782b-a8b9-4d71-9d1c-54a4031fb228",
            senderName: "Robert Ali",
            you: true,
            content:
              "Gut und selber? Hier der Link: https://platform.entwicklerheld.de/vacancy?hasRemoteJobs=true&latitude=0&longitude=0&radiusIndex=0&ratingIndex=0&pageID=1#searchField",
            serverTimeReceived: "2020-09-25T08:58:42.178628Z",
            unRead: false,
          },
          {
            id: "message002",
            senderId: "a33442ca-21a1-46b7-a52e-713a8ce018c0",
            senderName: "Philipp Dienst",
            you: false,
            content: "😂",
            serverTimeReceived: "2020-09-25T09:58:42.175791Z",
            unRead: false,
          },
          {
            id: "message001",
            senderId: "5e07782b-a8b9-4d71-9d1c-54a4031fb228",
            senderName: "Robert Ali",
            you: true,
            content: "😍",
            serverTimeReceived: "2020-09-25T10:58:42.172409Z",
            unRead: true,
          },
          {
            id: "message002",
            senderId: "a33442ca-21a1-46b7-a52e-713a8ce018c0",
            senderName: "Philipp Dienst",
            you: false,
            content:
              "https://img.netzwelt.de/dw1200_dh675_sw1280_sh720_sx0_sy0_sr16x9_nu0/picture/original/2022/06/game-of-thrones-daenerys-targaryen-343078.jpeg",
            serverTimeReceived: "2020-09-25T07:59:52.168542Z",
            unRead: true,
          },
          {
            id: "message002",
            senderId: "a33442ca-21a1-46b7-a52e-713a8ce018c0",
            senderName: "Philipp Dienst",
            you: true,
            content: "https://files.slack.com/files-pri/T02U86XU4-F060RHQT4CQ/image.png",
            serverTimeReceived: "2020-09-25T07:59:52.168542Z",
            unRead: true,
          },
          {
            id: "message002",
            senderId: "a33442ca-21a1-46b7-a52e-713a8ce018c0",
            senderName: "Philipp Dienst",
            you: true,
            content: "https://m.media-amazon.com/images/I/81VvIuEVeQL._AC_UF894,1000_QL80_.jpg",
            serverTimeReceived: "2020-09-25T07:59:52.168542Z",
            unRead: true,
          },
        ];
        result.apiData.draftMessage = {
          id: "message004",
          content: "Kein Plan.",
          serverTimeReceived: "2020-09-25T11:58:42.172409Z",
        };
      }

      if (channelId === "channel002" || channelId === "channel003") {
        result.apiData.userIsChannelMember = false;
        result.apiData.messages = [
          {
            id: "channel002",
            name: "Ameliorated 5thgeneration system engine",
            unreadMessage: Math.random() > 0.5,
            draftMessage: false,
            totalMessagesSent: 2,
            totalMessagesOfPlatformUser: 1,
            answeredMessage: true,
            members: [
              {
                id: "member002",
                name: "Felix Hanspach",
                image: "../assets/img/gavel.png",
                companyName: "polylith GbR",
                companyLogo: "../assets/img/eh-logo.png",
              },
              {
                id: "member002",
                name: "Norma Driske",
                image: "../assets/img/gavel.png",
                companyName: "",
                companyLogo: "",
              },
            ],
            isEmployeeChannelMember: false,
            sources: {
              jobRequestIds: ["3bbf2c27-f454-4f0b-9c98-874f06d30286"],
              sponsoredLeadIds: [],
            },
          },
        ];
      }
      result.loaded = true;
    }, 200);
    return result;
  }

  sendMessage(channelId, content) {
    let result = new SendMessageResult();
    setTimeout(function() {
      result.apiData = {
        messageId: "message001",
        serverTimeReceived: "2022-03-07T15:03:50.571567Z",
      };
      result.loaded = true;
    }, Math.random() * 1500);
    return result;
  }

  saveDraftMessage(channelId, content) {
    let result = new SaveDraftMessageResult();
    setTimeout(function() {
      result.apiData = {};
      result.loaded = true;
    }, Math.random() * 1500);
    return result;
  }

  getChannelId(jobRequestId, sponsoredLeadId) {
    let result = new GetChannelIdResult();
    setTimeout(function() {
      result.apiData = {
        channelId: "channel003",
      };
      result.loaded = true;
    }, Math.random() * 1500 + 1000);
    return result;
  }

  joinChannelForCompany(channelId) {
    let result = new JoinChannelForCompanyResult();
    setTimeout(function() {
      result.apiData = {
        memberId: "member001",
        channelMemberId: "channelMember001",
        channelId: channelId,
      };
      result.loaded = true;
    }, Math.random() * 1500);
    return result;
  }
}
