export class GetJobOfferIdsResult {
  constructor() {
    this.apiData = [];
    this.error = null;
    this.loaded = false;
  }
}

export class GetChannelsResult {
  constructor() {
    this.apiData = [];
    this.error = null;
    this.loaded = false;
  }
}

export class GetChannelDetailsResult {
  constructor() {
    this.apiData = {};
    this.error = null;
    this.loaded = false;
  }
}

export class SendMessageResult {
  constructor() {
    this.error = null;
    this.apiData = {};
    this.loaded = false;
  }
}

export class SaveDraftMessageResult {
  constructor() {
    this.error = null;
    this.apiData = {};
    this.loaded = false;
  }
}

export class GetChannelIdResult {
  constructor() {
    this.error = null;
    this.apiData = {};
    this.loaded = false;
  }
}

export class JoinChannelForCompanyResult {
  constructor() {
    this.error = null;
    this.apiData = {};
    this.loaded = false;
  }
}

