import {
  AcceptedChallengeDetails,
  AcceptChallengeResult,
  ChallengeDetails,
  ChallengeList,
  SolvedChallengesResult,
  SponsoredChallengesList,
  SubmitChallengeResult,
  SubmitTask,
  TaskList, DownloadChallengeSolutionZipResult, SponsoredCampaignStatistics,
} from "./TaskManagementServiceResponses";

import { responseHandler } from "../Utilities";

export class TaskManagementServiceNetwork {
  constructor(auth) {
    this.authService = auth;
  }

  accceptChallenge(challengeId, rewardOption, userUuid) {
    let result = new AcceptChallengeResult();
    fetch(window.TASK_API_URL + "/api/campaign/" + challengeId + "/accept/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify({
        reward_option: rewardOption,
        user_uuid: userUuid,
      }),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  submitChallenge(challengeId) {
    let result = new SubmitChallengeResult();
    fetch(window.TASK_API_URL + "/api/campaign/" + challengeId + "/submit/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getChallengeDetails(challengeIdOrSlug, region) {
    let result = new ChallengeDetails();
    let queryParams = "";
    if (region) {
      queryParams = "?region=" + region;
    }
    fetch(window.TASK_API_URL + "/api/campaign/" + challengeIdOrSlug + "/details/" + queryParams, {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getChallengeList(accepted, region) {
    let result = new ChallengeList();
    let queryParams = "";
    if (accepted) {
      queryParams = "?accepted=true";
    }
    if (region) {
      queryParams = "?region=" + region;
    }
    fetch(window.TASK_API_URL + "/api/campaign/" + queryParams, {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getSponsoredCampaignsList() {
    let result = new SponsoredChallengesList();
    fetch(window.TASK_API_URL + "/api/company/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getSponsoredCampaignStatistics(campaignId) {
    let result = new SponsoredCampaignStatistics();
    let url = window.TASK_API_URL + "/api/campaign/" + campaignId + "/statistics/";
    fetch(url, {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  submitTask(taskId, commitHash) {
    let result = new SubmitTask();
    let data = {
      commitHash: commitHash,
    };

    fetch(window.TASK_API_URL + "/api/campaigntask/" + taskId + "/submit/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json;
        result.loaded = true;
      })
      .catch((error) => {
        console.warn(error);
        result.error = error;
        result.loaded = true;
      });
    return result;
  }

  getDataOfMultipleChallenges(challengeIds) {
    let result = new ChallengeList();
    let data = {
      challengeIds: challengeIds,
    };

    fetch(window.TASK_API_URL + "/api/campaign/get_data_for_campaign_ids/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.warn(error);
        result.error = error;
        result.loaded = true;
      });
    return result;
  }

  getSolvedChallenge(userId) {
    let result = new SolvedChallengesResult();

    fetch(window.TASK_API_URL + "/public_api/user/" + userId + "/solved_challenges/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.warn(error);
        result.error = error;
        result.loaded = true;
      });
    return result;
  }

  downloadChallengeSolutionZip(acceptedChallengeUuid) {
    let result = new DownloadChallengeSolutionZipResult();

    fetch(window.TASK_API_URL + "/api/download_challenge_solution_zip/" + acceptedChallengeUuid + "/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.warn(error);
        result.error = error;
        result.loaded = true;
      });
    return result;
  }

  getDataOfMultipleChallengeTasks(challengeTaskIds) {
    let result = new TaskList();
    let data = {
      challengeTaskIds: challengeTaskIds,
    };

    fetch(window.TASK_API_URL + "/api/challengetask/get_data_for_challenge_task_ids/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "GET",
      body: JSON.stringify(data),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.warn(error);
        result.error = error;
        result.loaded = true;
      });
    return result;
  }

  getSolvedChallengesForPublicUserIds(publicUserIds) {
    let result = new TaskList();
    let data = {
      publicUserIds: publicUserIds,
    };

    fetch(window.TASK_API_URL + "/api/user/get_solved_challenges_by_public_user_ids/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.warn(error);
        result.error = error;
        result.loaded = true;
      });
    return result;
  }

  getAcceptedChallengeDetails(acceptedChallengeUuid) {
    let result = new AcceptedChallengeDetails();
    fetch(window.TASK_API_URL + "/api/accepted_challenge/" + acceptedChallengeUuid + "/details/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }
}
