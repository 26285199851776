import {
  EmployeeByUserIdResult,
  SponsoredChallengesResult,
  SponsoredLeadResult,
  UpdateSponsoredLeadResult,
  SponsoredChallengeDetails,
  PoolChallengesResult,
  UpdatePoolLeadResult,
  CompanyProfileResult,
  UpdateCompanyProfileResult,
  RequestVacancyOffer,
  RegistrationCodeResult,
} from "./CompanyServiceResponses";
import { PoolChallengeDetails, RegisterCompanyResult } from "@/services/company/CompanyServiceResponses";
import { responseHandler } from "@/services/Utilities";

let getEmployeeByUserIdApiData = {
  firstName: "",
  lastName: "",
  email: "",
  isRegistered: true,
  isCompanyActive: true,
  company: {
    id: "dad3f052-9d3b-4fbb-bbfa-b59766f32aea",
    name: "Gründungsprojekt polylith",
    teaserText:
      "Measure their up test early man reality hair. Family agency structure action.\r\nTurn quickly center there like someone with. Poor involve worker.\r\nContinue modern movement customer enter usually. Decision eat ever role significant mean today. Remember toward point. Exist rise head.\r\nEvent much pass movement because ago service. Republican front than building.\r\nPeople school dinner soldier choice family push lawyer. Successful indeed Democrat recently special west card because. To agent now.",
    logo: "http://localhost:8005/media/polylith_wK5ieRv.png",
    email: "",
    phone: "",
    website: "",
    techStack: ["Angular (JavaScript)", "Open UI 5 (JavaScript)", "JavaScript"],
    locations: [
      {
        address: "Downing St, Westminster, London SW1A, UK",
        city: "",
        country: "United Kingdom",
        latitue: 51.5032623,
        logitute: -0.1278772,
        zipCode: "",
      },
    ],
  },
};

export class CompanyServiceStub {
  constructor(auth) {
    this.authService = auth;
  }

  getSponsoredChallenges() {
    let result = new SponsoredChallengesResult();
    setTimeout(function() {
      result.apiData = [
        {
          id: "415c2bd8-f029-488d-a6e8-cffa47d9ad12",
          challenge_id: "e918f361-abe7-4187-a89a-8be94bd4f64b",
          company_id: "dad3f052-9d3b-4fbb-bbfa-b59766f32aea",
          start: "2019-05-01T00:00:00Z",
          end: "2050-06-01T00:00:00Z",
          accepted: 352,
          solved: 77,
        },
        {
          id: "315c2bd8-f029-488d-a6e8-cffa47d9ad12",
          challenge_id: "e6458744-d7bf-4fa1-8689-ef1643937339",
          company_id: "dad3f052-9d3b-4fbb-bbfa-b59766f32aea",
          start: "2019-05-01T00:00:00Z",
          end: "2050-06-01T00:00:00Z",
          accepted: 157,
          solved: 41,
        },
        {
          id: "215c2bd8-f029-488d-a6e8-cffa47d9ad12",
          challenge_id: "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
          company_id: "dad3f052-9d3b-4fbb-bbfa-b59766f32aea",
          start: "2019-05-01T00:00:00Z",
          end: "2050-06-01T00:00:00Z",
          accepted: 115,
          solved: 30,
        },
        {
          id: "115c2bd8-f029-488d-a6e8-cffa47d9ad12",
          challenge_id: "58c45208-c9cc-474e-94d4-ced187b077e0",
          company_id: "dad3f052-9d3b-4fbb-bbfa-b59766f32aea",
          start: "2019-05-01T00:00:00Z",
          end: "2050-06-01T00:00:00Z",
          accepted: 143,
          solved: 31,
        },
        {
          id: "515c2bd8-f029-488d-a6e8-cffa47d9ad12",
          challenge_id: "58c45208-c9cc-474e-94d4-ced187b077e0",
          company_id: "dad3f052-9d3b-4fbb-bbfa-b59766f32aea",
          start: "2019-05-01T00:00:00Z",
          end: "2050-06-01T00:00:00Z",
          accepted: 120,
          solved: 25,
        },
      ];
      result.loaded = true;
    }, Math.random() * 1500);
    return result;
  }

  getSponsoredChallengeDetails(sponsoredChallengeId) {
    let result = new SponsoredChallengeDetails();
    setTimeout(function() {
      result.apiData.challengeId = "58c45208-c9cc-474e-94d4-ced187b077e0";
      result.apiData.leads = [
        {
          id: "1",
          userId: "google-oauth2|103726051188520935837",
          anonymizedUserName: "somewhat",
          region: "Sachsen",
          profession: "Senior-Entwickler/in",
          solved: true,
          solvedDatetime: "2020-02-19T11:04:12Z",
          communicationState: "OPEN",
        },
        {
          id: "2",
          userId: "auth0|5c86419229db2e3735659934",
          anonymizedUserName: "somehow",
          region: "Thüringen",
          profession: "Schüler/in",
          solved: true,
          solvedDatetime: "2020-02-19T11:04:12Z",
          communicationState: "SENT",
        },
        {
          id: "3",
          userId: "auth0|5c8e7a2a3cb7a74a5bd57a71",
          anonymizedUserName: "somekind",
          region: "Berlin",
          profession: "Freelancer",
          solved: false,
          solvedDatetime: "",
          communicationState: "OPEN",
        },
      ];
      result.apiData.communicationStateChoices = [
        { label: "offen", value: "OPEN", disabled: false },
        { label: "reviewed", value: "REVIEWED", disabled: false },
        { label: "gesendet", value: "SENT", disabled: false },
        { label: "zugestellt", value: "DELIVERED", disabled: true },
        { label: "geantwortet", value: "RESPONDED", disabled: false },
      ];
      result.loaded = true;
    }, Math.random() * 1500);
    return result;
  }

  getSponsoredLead(leadId) {
    let result = new SponsoredLeadResult();
    setTimeout(function() {
      result.apiData = {
        lead: {
          id: "1",
          userId: "google-oauth2|103726051188520935837",
          anonymizedUserName: "somewhat",
          region: "Sachsen",
          profession: "Senior-Entwickler/in",
          solved: true,
          communicationState: "OPEN",
        },
        communicationStateChoices: [
          { label: "offen", value: "OPEN", disabled: false },
          { label: "reviewed", value: "REVIEWED", disabled: false },
          { label: "gesendet", value: "SENT", disabled: false },
          { label: "zugestellt", value: "DELIVERED", disabled: true },
          { label: "geantwortet", value: "RESPONDED", disabled: false },
        ],
      };
      result.loaded = true;
    }, Math.random() * 1500);
    return result;
  }

  updateSponsoredLead(leadId, communicationState) {
    let result = new UpdateSponsoredLeadResult();

    setTimeout(function() {
      result.loaded = true;
      result.error = null;
    }, Math.random() * 1500);

    return result;
  }

  updatePoolLead(leadId, communicationState) {
    let result = new UpdatePoolLeadResult();

    setTimeout(function() {
      result.loaded = true;
      result.error = null;
    }, Math.random() * 1500);

    return result;
  }

  getEmployeeByUserId() {
    let result = new EmployeeByUserIdResult();
    setTimeout(function() {
      result.loaded = true;
      result.error = null;
      result.apiData = getEmployeeByUserIdApiData;
    }, Math.random() * 1500);

    return result;
  }

  getEmployeeByUserIdPromise() {
    return new Promise(function(resolve) {
      let result = new EmployeeByUserIdResult();
      result.loaded = true;
      result.error = null;
      result.apiData = getEmployeeByUserIdApiData;
      setTimeout(function() {
        resolve(result);
      }, Math.random() * 1500);
    });
  }

  getPoolChallenges() {
    let result = new PoolChallengesResult();
    setTimeout(function() {
      result.apiData = [
        {
          id: "ea0d8bc9-8195-492a-b37b-a89ba4dc618b",
          challengeId: "bf876a38-3f94-4c65-a111-10b8e74e9623",
          companyId: "dad3f052-9d3b-4fbb-bbfa-b59766f32aea",
          start: "2019-05-01T00:00:00Z",
          end: "2050-06-01T00:00:00Z",
          accepted: 320,
          solved: 72,
        },
        {
          id: "b8967fcd-b53d-440d-979a-2f47b4214870",
          challengeId: "42d9c6e2-5948-49bc-8e70-52cadba58154",
          companyId: "dad3f052-9d3b-4fbb-bbfa-b59766f32aea",
          start: "2019-05-01T00:00:00Z",
          end: "2050-06-01T00:00:00Z",
          accepted: 157,
          solved: 41,
        },
        {
          id: "3fc2631f-a25e-4e5f-a43e-dbbe993b0408",
          challengeId: "4dc8cf16-2783-445c-abc1-485d35280b16",
          companyId: "dad3f052-9d3b-4fbb-bbfa-b59766f32aea",
          start: "2019-05-01T00:00:00Z",
          end: "2050-06-01T00:00:00Z",
          accepted: 115,
          solved: 30,
        },
        {
          id: "54399c5f-52d6-4494-822e-e23612890bf4",
          challengeId: "56b3cdef-8754-444a-a5cd-a7972c2a6d96",
          companyId: "dad3f052-9d3b-4fbb-bbfa-b59766f32aea",
          start: "2019-05-01T00:00:00Z",
          end: "2050-06-01T00:00:00Z",
          accepted: 143,
          solved: 31,
        },
      ];
      result.loaded = true;
    }, Math.random() * 1500);
    return result;
  }

  getPoolChallengeDetails(poolChallengeId) {
    let result = new PoolChallengeDetails();
    setTimeout(function() {
      result.apiData.challengeId = "e918f361-abe7-4187-a89a-8be94bd4f64b";
      result.apiData.leads = [
        {
          id: "1",
          userId: "google-oauth2|103726051188520935837",
          anonymizedUserName: "somewhat",
          region: "Sachsen",
          profession: "Senior-Entwickler/in",
          solved: true,
          solvedDatetime: "2020-02-19T11:04:12Z",
          communicationState: "SENT",
        },
        {
          id: "2",
          userId: "auth0|5c86419229db2e3735659934",
          anonymizedUserName: "somehow",
          region: "Thüringen",
          profession: "Schüler/in",
          solved: true,
          solvedDatetime: "2020-02-19T11:04:12Z",
          communicationState: "OPEN",
        },
        {
          id: "3",
          userId: "auth0|5c8e7a2a3cb7a74a5bd57a71",
          anonymizedUserName: "somekind",
          region: "Berlin",
          profession: "Freelancer",
          solved: false,
          solvedDatetime: "",
          communicationState: "OPEN",
        },
        {
          id: "4",
          userId: "auth0|5c8e7a2a3cb7a74a5bd57a71",
          anonymizedUserName: "someone",
          region: "Hamburg",
          profession: "Student/in",
          solved: false,
          solvedDatetime: "",
          communicationState: "RESPONDED",
        },
      ];
      result.apiData.communicationStateChoices = [
        { label: "offen", value: "OPEN", disabled: false },
        { label: "reviewed", value: "REVIEWED", disabled: false },
        { label: "gesendet", value: "SENT", disabled: false },
        { label: "zugestellt", value: "DELIVERED", disabled: true },
        { label: "geantwortet", value: "RESPONDED", disabled: false },
      ];
      result.loaded = true;
    }, Math.random() * 1500);
    return result;
  }

  getPoolLead(leadId) {
    let result = new SponsoredLeadResult();
    setTimeout(function() {
      result.apiData = {
        lead: {
          id: "1",
          userId: "google-oauth2|103726051188520935837",
          anonymizedUserName: "somewhat",
          region: "Sachsen",
          profession: "Senior-Entwickler/in",
          solved: true,
          communicationState: "OPEN",
        },
        communicationStateChoices: [
          { label: "offen", value: "OPEN", disabled: false },
          { label: "reviewed", value: "REVIEWED", disabled: false },
          { label: "gesendet", value: "SENT", disabled: false },
          { label: "zugestellt", value: "DELIVERED", disabled: true },
          { label: "geantwortet", value: "RESPONDED", disabled: false },
        ],
      };
      result.loaded = true;
    }, Math.random() * 1500);
    return result;
  }

  getCompanyProfile(companyId) {
    let result = new CompanyProfileResult();
    setTimeout(function() {
      result.apiData = {
        companyId: "ac7f1163-533b-4d54-9e20-6f36c241f423",
        profileId: 8,
        companySlug: "queo-gmbh",
        statisticsUpsale: true,
        valuesUpsale: true,
        videoUpsale: true,
        profile: {
          name: "queo",
          slogan:
            "Vom Status quo zum Status queo - Das nächste Level erreichen! Mit diesem Anspruch entwickeln wir Markenkommunikation und Kampagnen, Websites und Portale sowie individuelle Softwarelösungen.",
          aboutUs:
            "Das nächste Level erreichen! Mit diesem Anspruch entwickeln wir Markenkommunikation und Kampagnen, Websites und Portale sowie individuelle Softwarelösungen. Insgesamt 150 queos arbeiten täglich im Spannungsfeld von Kommunikation und Technologie. Mit Neugier und Teamgeist treiben wir innovative Projekte voran. Wir gehen individuelle Wege zielgerichtet zur besten Lösung. Wir sind ein starkes Team. Gemeinsam wachsen wir an jeder neuen Herausforderung. queo, das sind die queo GmbH in Dresden, die queo Berlin GmbH und die queo swiss AG in Solothurn.",
          careerPage: "https://entwicklerheld.de",
          logo:
            "https://production-company-media-storage.s3.amazonaws.com/company_profiles/company_queo-gmbh/queo_sm.png",
          logoIcon:
            "https://production-company-media-storage.s3.amazonaws.com/company_profiles/company_queo-gmbh/queo_sm.png",
          heroImage:
            "https://production-company-media-storage.s3.amazonaws.com/company_profiles/company_queo-gmbh/queo_hero.jpg",
          video: "",
          sliderImage1:
            "https://production-company-media-storage.s3.amazonaws.com/company_profiles/company_queo-gmbh/queo_hero.jpg",
          sliderImage2:
            "https://production-company-media-storage.s3.amazonaws.com/company_profiles/company_queo-gmbh/queo_hero.jpg",
          sliderImage3:
            "https://production-company-media-storage.s3.amazonaws.com/company_profiles/company_queo-gmbh/queo_hero.jpg",
          remoteOption: "REMOTE_75",
          remoteChoices: [
            {"nameCode": "REMOTE_0", "name": "0%"},
            {"nameCode": "REMOTE_25", "name": "25%"},
            {"nameCode": "REMOTE_50", "name": "50%"},
            {"nameCode": "REMOTE_75", "name": "75%"},
            {"nameCode": "REMOTE_100", "name": "100%"},
          ],
        },
        values: [],
        technologies: [
          {
            id: 1,
            name: "Django",
            label: "Django (Python)",
            logo: "https://res.cloudinary.com/dspi5zyaa/image/upload/v1600805975/eh-platform/company.platform.entwicklerheld.de/technologies/ah6ajjkuueqlslt2g4fb.png",
            language: "Python",
          },
          {
            id: 2,
            name: "Kubernetes",
            label: "Kubernetes",
            logo: "https://res.cloudinary.com/dspi5zyaa/image/upload/v1600806010/eh-platform/company.platform.entwicklerheld.de/technologies/oobpxhsjfqukj2m8xukx.png",
            language: "",
          },
          {
            id: 3,
            name: "Spring Boot",
            label: "Spring Boot (Java)",
            language: "Java",
            logo: "https://res.cloudinary.com/dspi5zyaa/image/upload/v1600806017/eh-platform/company.platform.entwicklerheld.de/technologies/csnat4m8al1gz40qoi1t.png"
          },
          {
            id: 4,
            name: "Angular",
            label: "Angular (JavaScript)",
            language: "JavaScript",
            logo: "https://res.cloudinary.com/dspi5zyaa/image/upload/v1613393870/eh-platform/company.platform.entwicklerheld.de/technologies/va6iiax36uandemyz1c2.png"
          },
          {
            id: 5,
            name: "SAP S/4 HANA",
            label: "SAP S/4 HANA (ABAP)",
            language: "ABAP",
            logo: "https://res.cloudinary.com/dspi5zyaa/image/upload/v1600806019/eh-platform/company.platform.entwicklerheld.de/technologies/pllunn9kvtbh1vkal0wo.png"
          },
        ],
        technologyChoices: [
          {
            id: 7,
            name: "",
            language: "JavaScript",
            label: "JavaScript",
            logo: "https://res.cloudinary.com/dspi5zyaa/image/upload/v1600805980/eh-platform/company.platform.entwicklerheld.de/technologies/pbqmnjz6nhsxdpinaqwp.png"
          },
          {
            id: 8,
            name: "",
            language: "Python",
            label: "Python",
            logo: "https://res.cloudinary.com/dspi5zyaa/image/upload/v1600806030/eh-platform/company.platform.entwicklerheld.de/technologies/kfsp2ntwer8fptzpv4qj.png"
          },
          {
            id: 10,
            name: "Laravel",
            language: "PHP",
            label: "Laravel (PHP)",
            logo: "https://res.cloudinary.com/dspi5zyaa/image/upload/v1600806020/eh-platform/company.platform.entwicklerheld.de/technologies/j3uwqlco6jexhb6gxjjq.png"
          },
        ],
        valueChoices: [
          {
            nameCode: "AGILE_METHODS",
            name: "Agile Arbeitsmethoden",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/AGILE_METHODS.png",
          },
          {
            nameCode: "APPRENTICESHIPS",
            name: "Ausbildungsplätze",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/APPRENTICESHIPS.png",
          },
          {
            nameCode: "B2B",
            name: "B2B",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/B2B.png",
          },
          {
            nameCode: "B2C",
            name: "B2C",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/B2C.png",
          },
          {
            nameCode: "COMMUNITY_SUPPORT",
            name: "Engagement für Communities",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/COMMUNITY_SUPPORT.png",
          },
          {
            nameCode: "CONTINUOUS_DELIVERY",
            name: "Continuous Delivery",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/CONTINUOUS_DELIVERY.png",
          },
          {
            nameCode: "CUSTOMER_FIRST",
            name: "Der Kunde ist König",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/CUSTOMER_FIRST.png",
          },
          {
            nameCode: "DATA_DRIVEN",
            name: "Data Driven",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/DATA_DRIVEN.png",
          },
          {
            nameCode: "DESIGN_DRIVEN",
            name: "Design Driven",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/DESIGN_DRIVEN.png",
          },
          {
            nameCode: "DIVERSITY",
            name: "Diversität",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/DIVERSITY.png",
          },
          {
            nameCode: "EFFECTIVE_MEETING_CULTURE",
            name: "Effektive Meetingkultur",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/EFFECTIVE_MEETING_CULTURE.png",
          },
          {
            nameCode: "ENGINEERING_DRIVEN",
            name: "Engineering Driven",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/ENGINEERING_DRIVEN.png",
          },
          {
            nameCode: "FAILURE_CULTURE",
            name: "Konstruktive Fehlerkultur",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/FAILURE_CULTURE.png",
          },
          {
            nameCode: "FAMILY_FRIENDLY",
            name: "Familienfreundlichkeit",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/FAMILY_FRIENDLY.png",
          },
          {
            nameCode: "FEEDBACK",
            name: "Feedbackkultur",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/FEEDBACK.png",
          },
          {
            nameCode: "FLAT_HIERARCHIES",
            name: "Flache Hierarchien",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/FLAT_HIERARCHIES.png",
          },
          {
            nameCode: "FLEXIBLE_WORKING_ENV",
            name: "Flexible Arbeitsumgebung",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/FLEXIBLE_WORKING_ENV.png",
          },
          {
            nameCode: "HEART_FOR_JUNIOR_DEVS",
            name: "Herz für Juniorentwickler",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/HEART_FOR_JUNIOR_DEVS.png",
          },
          {
            nameCode: "HIGH_QUALITY_CODEBASE",
            name: "Hochqualitative Codebase",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/HIGH_QUALITY_CODEBASE.png",
          },
          {
            nameCode: "INCLUSION",
            name: "Inklusion",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/INCLUSION.png",
          },
          {
            nameCode: "MODERN_OFFICES",
            name: "Moderne Arbeitsplätze",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/MODERN_OFFICES.png",
          },
          {
            nameCode: "OPEN_COMMUNICATION",
            name: "Transparente Kommunikation",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/OPEN_COMMUNICATION.png",
          },
          {
            nameCode: "OPEN_SOURCE_CONTRIBUTOR",
            name: "Open Source Contributor",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/OPEN_SOURCE_CONTRIBUTOR.png",
          },
          {
            nameCode: "PAIR_PROGRAMMING",
            name: "Pair Programming",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/PAIR_PROGRAMMING.png",
          },
          {
            nameCode: "PERSONAL_DEVELOPMENT",
            name: "Persönliche Weiterentwicklung",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/PERSONAL_DEVELOPMENT.png",
          },
          {
            nameCode: "PRODUCT_DRIVEN",
            name: "Product Driven",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/PRODUCT_DRIVEN.png",
          },
          {
            nameCode: "SELF_FUNDED",
            name: "Eigenfinanziert",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/SELF_FUNDED.png",
          },
          {
            nameCode: "START_UP",
            name: "Startup",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/START_UP.png",
          },
          {
            nameCode: "TEAM_EVENTS",
            name: "Team Events",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/TEAM_EVENTS.png",
          },
          {
            nameCode: "TEAM_LUNCH",
            name: "Gemeinsames Mittagessen",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/TEAM_LUNCH.png",
          },
          {
            nameCode: "TEAM_ORIENTATION",
            name: "Teamorientierung",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/TEAM_ORIENTATION.png",
          },
          {
            nameCode: "TECHNICAL_BACKGROUND",
            name: "Gründer mit technischem Hintergrund",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/TECHNICAL_BACKGROUND.png",
          },
          {
            nameCode: "TEST_DRIVEN",
            name: "Test Driven",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/TEST_DRIVEN.png",
          },
          {
            nameCode: "TRAININGS",
            name: "Fortbildung",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/TRAININGS.png",
          },
          {
            nameCode: "WORK_LIFE_BALANCE",
            name: "Work-Life-Balance",
            icon: "http://company.platform.entwicklerheld.de/static/profile_icons/WORK_LIFE_BALANCE.png",
          },
        ],
        locations: [
          {
            address: "Tharandter Str. 13",
            zipCode: "01159",
            city: "Dresden",
            country: "Deutschland",
            latitude: 51.04036,
            longitude: 13.702511,
          },
        ],
        socialMediaLinks: [
          { platform: "facebook", link: "https://www.facebook.com/queocorporate" },
          { platform: "twitter", link: "https://twitter.com/queo_corporate?lang=de" },
          { platform: "xing", link: "https://www.xing.com/companies/queogmbh" },
          { platform: "linkedin", link: "https://www.linkedin.com/company/queo-gmbh/?originalSubdomain=de" },
          { platform: "website", link: "https://www.linkedin.com/company/queo-gmbh/?originalSubdomain=de" },
        ],
        socialMediaChoices: [
          { platform: "facebook", label: "Facebook" },
          { platform: "twitter", label: "Twitter" },
          { platform: "github", label: "GitHub" },
          { platform: "instagram", label: "Instagram" },
          { platform: "xing", label: "Xing" },
          { platform: "linkedin", label: "LinkedIn" },
          { platform: "website", label: "Website" },
          { platform: "youtube", label: "YouTube" },
          { platform: "kununu", label: "Kununu" },
          { platform: "spotify", label: "Spotify" },
        ],
        statistics: [],
        statisticChoices: [
          { nameCode: "AVERAGE_AGE", name: "Durchschnittsalter", icon: "birthday-cake" },
          { nameCode: "AVERAGE_CODE_COVERAGE", name: "Code Coverage", icon: "check-circle" },
          { nameCode: "AVERAGE_TEAM_SIZE", name: "Durchschnittliche Teamgröße", icon: "arrows-alt" },
          { nameCode: "CUSTOMERS", name: "Glückliche Kunden", icon: "thumbs-o-up" },
          { nameCode: "DAILY_COFFEE", name: "Tassen Kaffee pro Tag", icon: "coffee" },
          { nameCode: "DAILY_COMMITS", name: "Commits pro Tag", icon: "code-fork" },
          { nameCode: "DAILY_EMOJIS", name: "Benutzte Emojis pro Tag", icon: "smile-o" },
          { nameCode: "EMPLOYEES", name: "Mitarbeiter", icon: "sitemap" },
          { nameCode: "FINISHED_PROJECTS", name: "Abgeschlossene Projekte", icon: "trophy" },
          { nameCode: "INTERNSHIPS", name: "Ausbildungsplätze", icon: "graduation-cap" },
          { nameCode: "JUNIOR_DEVELOPERS", name: "Junior-Entwickler", icon: "child" },
          { nameCode: "LINES_OF_CODE", name: "Lines of Code", icon: "file-text-o" },
          { nameCode: "LINUX_USER", name: "Linux-Nutzer in %", icon: "linux" },
          { nameCode: "MAC_USER", name: "MAC-Nutzer in %", icon: "apple" },
          { nameCode: "PETS", name: "Haustiere", icon: "paw" },
          { nameCode: "PROGRAMMING_LANGUAGES", name: "Verschiedene Programmiersprachen", icon: "keyboard-o" },
          { nameCode: "SPOKEN_LANGUAGES", name: "Gesprochene Sprachen", icon: "language" },
          { nameCode: "SUPPORTED_COMMUNITIES", name: "Unterstützte Communities", icon: "heart-o" },
          { nameCode: "USERS", name: "Zufriedene Nutzer", icon: "users" },
          { nameCode: "WINDOWS_USER", name: "Windows-Nutzer in %", icon: "windows" },
          { nameCode: "YEARLY_CHOCOLATE", name: "Schokoladenkonsum pro Jahr in kg", icon: "th" },
          { nameCode: "YEARLY_CONFERENCES", name: "Besuchte Konferenzen pro Jahr", icon: "slideshare" },
          { nameCode: "YEARLY_TEAM_EVENTS", name: "Teamevents pro Jahr", icon: "music" },
        ],
        maxUploadSizeImage: 1572864,
        maxUploadSizeVideo: 50000000,
        vacancyRequestSubmitted: false,
      };
      result.loaded = true;
    }, Math.random() * 1500);
    return result;
  }

  updateCompanyProfile() {
    let result = new UpdateCompanyProfileResult();
    setTimeout(function() {
      result.error = "error";
      result.loaded = true;
    }, Math.random() * 1500);
    return result;
  }

  registerCompany() {
    let result = new RegisterCompanyResult();
    setTimeout(function() {
      result.loaded = true;
      result.error = null;
    }, Math.random() * 1500);
    return result;
  }

  requestVacancyOffer() {
    let result = new RequestVacancyOffer();
    setTimeout(function() {
      result.loaded = true;
      result.error = null;
    }, Math.random() * 1500);
    return result;
  }

  getRegistrationCode(registrationCode) {
    let result = new RegistrationCodeResult();
    setTimeout(function() {
      result.loaded = true;
      result.apiData = {
        code: "TEST",
        name: "Max Muster GmbH",
        logo: "https://res.cloudinary.com/dspi5zyaa/image/upload/v1613052889/eh-platform/logos/eh-logo.png",
        used: false,
      };
      result.error = null;
    }, Math.random() * 1500);
    return result;
  }
}
