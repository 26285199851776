<template>
  <div class="root-wrapper">
    <div id="popover-completion-step" class="wrapper position-relative text-center" @click="goToProfile()">
      <svg style=" z-index: 11;" viewBox="0 0 36 36" class="circular-chart">
        <path
          class="circle-background"
          stroke-dasharray="100, 100"
          d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          class="circle"
          :style="'stroke-dasharray: ' + actualProgress + ' 100;'"
          d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
      <transition name="fade" mode="out-in">
        <div v-if="loaded && !error" class="diagram-description" @mouseover="hover = true" @mouseleave="hover = false">
          <img class="logo" :src="companyLogo" />
        </div>
      </transition>
      <transition name="bounce">
        <div v-if="hover" class="company-progress-percentage">
          <div
            ><strong>Fortschritt <br />{{ actualProgress }}%</strong></div
          >
        </div>
      </transition>
    </div>
    <transition name="fade" mode="out-in">
      <b-popover
        v-if="hasOpenSteps.length"
        target="popover-completion-step"
        triggers="hover"
        placement="right"
        custom-class="d-none d-md-block completion-popover"
      >
        <template #title>Nächster Schritt</template>
        <p-profile-completion-step
          v-if="loaded && !error"
          :profile-request="companyProfileRequest"
          :is-paying-customer="isPayingCustomer"
        />
      </b-popover>
    </transition>
    <transition name="fade" mode="out-in">
      <p-profile-completion-step
        v-if="loaded && !error"
        class="d-block d-md-none"
        :profile-request="companyProfileRequest"
        :is-paying-customer="isPayingCustomer"
      />
    </transition>
  </div>
</template>

<script>
import { CompanyProfileResult } from "@/services/company/CompanyServiceResponses";
import PProfileCompletionStep from "@/components/profile/PProfileCompletionStep";
import { services } from "@/main";
import { ContentLoader } from "vue-content-loader";
import { companyProgressSteps } from "@/const";
import { eventBus } from "@/eventbus";
import { navigateTo } from "@/utils/routing";

export default {
  name: "CCompanyProgress",
  components: { PProfileCompletionStep },
  props: {
    companyId: { type: String, required: true },
  },
  data() {
    return {
      companyProfileRequest: new CompanyProfileResult(),
      isPayingCustomer: services.authenticationService.isPayingCustomer(),
      actualProgress: 0,
      companyLogo: "",
      hover: false,
    };
  },
  computed: {
    loaded: function() {
      return this.companyProfileRequest.loaded;
    },
    error: function() {
      return this.companyProfileRequest.error;
    },
    profileData: function() {
      if (!this.companyProfileRequest.loaded || this.companyProfileRequest.error) {
        return null;
      }
      return this.companyProfileRequest.apiData;
    },
    isPremiumCustomer: function() {
      // TODO: we need to check here if the user is a premium customer
      return false;
    },
    hasOpenSteps: function() {
      if (!this.companyProfileRequest.loaded || this.companyProfileRequest.error) {
        return [];
      }
      return companyProgressSteps.filter(
        (step) => !step.completionHandler(this.companyProfileRequest.apiData, this.isPayingCustomer)
      );
    },
  },
  watch: {
    "companyProfileRequest.loaded": function() {
      if (!this.companyProfileRequest.loaded || this.companyProfileRequest.error) {
        return;
      }
      this.companyLogo = this.getCompanyLogo();
      this.actualProgress = this.getProgressPercentage();
      this.saveCompanyInformationToCookie(
        "CompanyInformation",
        this.profileData.profile.name + ";" + this.companyLogo,
        10
      );
    },
  },
  mounted() {
    this.sendCompanyProfileRequest();
    eventBus.$on("updatedProfileLoaded", () => {
      this.sendCompanyProfileRequest();
    });
    eventBus.$on("submittedJobOfferRequest", () => {
      this.sendCompanyProfileRequest();
    });
  },
  beforeDestroy() {
    eventBus.$off("updatedProfileLoaded");
    eventBus.$off("submittedJobOfferRequest");
  },
  methods: {
    getCompanyLogo: function() {
      if (this.profileData.profile.logo) {
        return this.profileData.profile.logo;
      }
      return "https://polylith-public-files.s3.eu-central-1.amazonaws.com/logo-auth.png";
    },
    getProgressPercentage: function() {
      let relevantCompanyProgressSteps = [];
      if (!this.isPremiumCustomer) {
        relevantCompanyProgressSteps = companyProgressSteps.filter((step) => !step.isPremium);
      }
      const solvedCount = relevantCompanyProgressSteps.filter((step) =>
        step.completionHandler(this.profileData, this.isPayingCustomer)
      ).length;
      let percentage = (solvedCount / relevantCompanyProgressSteps.length) * 100;
      return percentage.toFixed(0);
    },
    sendCompanyProfileRequest: function() {
      this.companyProfileRequest = services.companyService.getCompanyProfile(this.companyId);
    },
    goToProfile: function() {
      navigateTo(this.$router, { name: "profile" });
    },
    saveCompanyInformationToCookie(name, value, days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      // encode to base64
      document.cookie = name + "=" + (btoa(value) || "") + expires + "; path=/";
    },
  },
};
</script>

<style scoped>
.circular-chart {
  display: block;
  height: 70px;
  width: 70px;
  margin: 10px auto 1.3rem;
}

.circle-background {
  stroke: #e2e2e2;
  fill: white;
  stroke-width: 2.8;
}

.circle {
  stroke: #60be9b;
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  transition: 0.5s ease-in-out all;
}

.diagram-description {
  position: absolute;
  top: 35px;
  margin: auto;
  left: 40px;
  transform: translate(-50%, -50%);
  height: 58px;
  width: 58px;
  background-color: white;
  border-radius: 50%;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.wrapper {
  max-width: 250px;
}

.wrapper:hover {
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}

.company-progress-percentage {
  position: absolute;
  top: 35px;
  left: 40px;
  transform: translate(-50%, -50%);
  background-color: rgba(96, 187, 155, 0.8);
  pointer-events: none;
  height: 58px;
  width: 58px;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  color: white;
}

.company-progress-percentage div {
  margin: auto;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.4s reverse;
}
@keyframes bounce-in {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@media (max-width: 768px) {
  .circular-chart {
    height: 125px;
    width: 125px;
  }
  .diagram-description {
    top: 63px;
    left: 126px;
    height: 90px;
    width: 90px;
  }
  .company-progress-percentage {
    top: 63px;
    left: 126px;
    height: calc(100% - 20px);
    width: calc(50% - 20px);
    font-size: 15px;
  }
}
</style>
