import { CookieAcceptResult, CookieDeclineResult } from "./CookieConsentServiceResponses";

export default class CookieConsentServiceStub {
  constructor(auth) {
    this.authService = auth;
  }

  setCookie(name, value, days) {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  decline() {
    let result = new CookieDeclineResult();

    setTimeout(() => {
      result.apiData = {};
      result.loaded = true;
      this.setCookie(window.COOKIE_CONSENT_NAME, "statistics=" + window.COOKIE_CONSENT_DECLINE_VALUE, 30);
    }, 200);
    return result;
  }

  accept() {
    let result = new CookieAcceptResult();

    setTimeout(() => {
      result.apiData = {};
      result.loaded = true;
      this.setCookie(window.COOKIE_CONSENT_NAME, "statistics=1", 30);
    }, 200);
    return result;
  }
}
