import ResumeServiceNetwork from "@/services/resume/ResumeServiceNetwork";
import ResumeServiceStub from "@/services/resume/ResumeServiceStub";

export default class ResumeService {
  constructor(auth) {
    this.authService = auth;
  }

  getResumeByAccessId(accessId) {
    if (typeof window.RESUME_API_URL === "undefined" || window.RESUME_API_URL === "") {
      console.info("window.RESUME_API_URL is not set! Using Stub data!");
      return new ResumeServiceStub(this.authService).getResumeByAccessId(accessId);
    }
    return new ResumeServiceNetwork(this.authService).getResumeByAccessId(accessId);
  }
}
