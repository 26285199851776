import { ApiError, responseHandler } from "../Utilities";
import { CookieAcceptResult, CookieDeclineResult } from "./CookieConsentServiceResponses";

export default class CookieConsentServiceNetwork {
  constructor(auth) {
    this.authService = auth;
  }

  decline() {
    let result = new CookieDeclineResult();
    fetch(window.COOKIE_CONSENT_API_URL + "/decline/", {
      method: "POST",
      credentials: "include",
      headers: new Headers({ "X-Requested-With": "XMLHttpRequest" }),
    })
      .then((response) => {
        let statusMessage = "Error occurred with status " + response.status;
        if (response.status.toString()[0] !== "2") {
          throw new ApiError(response.status, {}, statusMessage);
        }
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });

    return result;
  }

  accept() {
    let result = new CookieAcceptResult();
    fetch(window.COOKIE_CONSENT_API_URL + "/accept/", {
      method: "POST",
      credentials: "include",
      headers: new Headers({ "X-Requested-With": "XMLHttpRequest" }),
    })
      .then((response) => {
        let statusMessage = "Error occurred with status " + response.status;
        if (response.status.toString()[0] !== "2") {
          throw new ApiError(response.status, {}, statusMessage);
        }
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }
}
