import VueRouter from "vue-router";
const { isNavigationFailure, NavigationFailureType } = VueRouter;

export function navigateTo(router, ...params) {
  router.push(...params).catch((failure) => {
    // It can be, that we redirect to keep query params like registration code
    if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
      return;
    }
    console.error(failure);
  });
}
