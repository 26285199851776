import APIResult from "@/services/APIResult";

export class ChallengeDetails extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}

export class ChallengeList extends APIResult {
  constructor() {
    super();
    this.apiData = [];
  }
}

export class SponsoredChallengesList extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}

export class SponsoredCampaignStatistics extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}

export class SubmitTask extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}

export class AcceptChallengeResult extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}

export class SubmitChallengeResult extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}

export class SolvedChallengesResult extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}

export class DownloadChallengeSolutionZipResult extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}

export class SolvedChallengesResultDict extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}

export class TaskList extends APIResult {
  constructor() {
    super();
    this.apiData = [];
  }
}

export class AcceptedChallengeDetails extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}
