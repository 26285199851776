import { responseHandler } from "@/services/Utilities";
import { ResumeResult } from "@/services/resume/ResumeServiceResponses";

export default class ResumeServiceNetwork {
  constructor(auth) {
    this.authService = auth;
  }

  getResumeByAccessId(accessId) {
    let result = new ResumeResult();
    fetch(window.RESUME_API_URL + "/api/resume/get_resume_by_access_id/" + accessId + "/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }
}
