export default class GoogleAnalyticsService {
  constructor(gtag) {
    this.$gtag = gtag;
  }

  sendEvent(category, action, label) {
    if (this.$gtag) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    }
  }

  setUserId(userId) {
    if (this.$gtag) {
      this.$gtag.set({ user_id: userId });
    }
  }

  sendPageView() {
    if (this.$gtag) {
      this.$gtag.pageview();
    }
  }
}
