import APIResult from "@/services/APIResult";

export class SponsoredChallengesResult extends APIResult {
  constructor() {
    super();
    this.apiData = [];
  }
}

export class PoolChallengesResult extends APIResult {
  constructor() {
    super();
    this.apiData = [];
  }
}

export class SponsoredChallengeDetails extends APIResult {
  constructor() {
    super();
    this.apiData = {};
    this.apiData.challengeId = "";
    this.apiData.leads = [];
    this.apiData.communicationStateChoices = [];
  }
}

export class PoolChallengeDetails extends APIResult {
  constructor() {
    super();
    this.apiData = {};
    this.apiData.challengeId = "";
    this.apiData.leads = [];
    this.apiData.communicationStateChoices = [];
  }
}

export class SponsoredLeadResult extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}

export class UpdateSponsoredLeadResult extends APIResult {
  constructor() {
    super();
  }
}

export class UpdatePoolLeadResult extends APIResult {
  constructor() {
    super();
  }
}

export class EmployeeByUserIdResult extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}

export class CompanyProfileResult extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}

export class UpdateCompanyProfileResult extends APIResult {
  constructor() {
    super();
  }
}

export class RegisterCompanyResult extends APIResult {
  constructor() {
    super();
  }
}

export class RequestVacancyOffer extends APIResult {
  constructor() {
    super();
  }
}

export class RegistrationCodeResult extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}
