import APIResult from "@/services/APIResult";

export class Vacancies extends APIResult {
  constructor() {
    super();
    this.apiData = [];
  }
}

export class Candidates extends APIResult {
  constructor() {
    super();
    this.apiData = [];
  }
}

export class SetCandidateNote extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}

export class UpdateCandidateFlags extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}
