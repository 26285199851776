import { services } from "@/main";
export class Cache {
  constructor() {
    this._cache = new Map();
  }

  pushToCache(key, value) {
    this._cache.set(key, { value: value, timestamp: Date.now() });
  }

  getValueFromCache(key) {
    let valueFromCache = this._cache.get(key);
    if (!valueFromCache) {
      return null;
    }

    let now = Date.now();
    let difference = now - valueFromCache.timestamp;
    if (difference > 5000) {
      return null;
    }

    return valueFromCache.value;
  }
}

export async function responseHandler(response) {
  let statusMessage = "Error occurred with status " + response.status;
  if (response.status !== 200) {
    let errorMessage;
    let json = {};
    try {
      json = await response.json();
      const error = json.error;
      errorMessage = statusMessage + ": " + error.message;
      if (error.fields) {
        errorMessage += " | " + JSON.stringify(error.fields, null, 2);
      }
    } catch (err) {
      errorMessage =
        "Da ist etwas schief gelaufen. Versuche es noch einmal oder schreibe uns eine E-Mail an hallo@entwicklerheld.de";
    }
    throw new ApiError(response.status, json.error, errorMessage);
  }
  return response.json();
}

export class ApiError extends Error {
  constructor(status, additionalData, ...args) {
    super(...args);
    this.status = status;
    this.additionalData = additionalData;
  }
}

export function findGetParameter(parameterName) {
  let result = null;
  let tmp = [];
  let items = location.search.substr(1).split("&");
  for (let index = 0; index < items.length; index++) {
    tmp = items[index].split("=");
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
}

export function isCookieConsentSet() {
  let consent = document.cookie.split(";").filter((item) => item.includes(window.COOKIE_CONSENT_NAME));
  if (consent.length === 0) {
    return false;
  }
  return true;
}

export function getCookieConsent() {
  let consent = document.cookie.split(";").filter((item) => item.includes(window.COOKIE_CONSENT_NAME));
  if (consent.length === 0) {
    return false;
  }
  return !consent[0].includes("=" + window.COOKIE_CONSENT_DECLINE_VALUE);
}
