import io from "socket.io-client";

export class NotificationsServiceNetwork {
  constructor(auth) {
    this.authService = auth;
  }

  getNewMessageNotificationSocket() {
    return io(window.NOTIFICATIONS_API_URL);
  }
}
