import APIResult from "@/services/APIResult";

export class QualityReport extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}

export class HistoryReport extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}

export class Scores extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}

export class Measures extends APIResult {
  constructor() {
    super();
    this.apiData = {};
  }
}
