import { TaskManagementServiceStub } from "./TaskManagementStubService";
import { TaskManagementServiceNetwork } from "./TaskManagementNetworkService";

export default class TaskManagementService {
  constructor(auth) {
    this.authService = auth;
  }

  accceptChallenge(challengeId, rewardOption, userUuid) {
    if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
      console.info("window.TASK_API_URL is not set! Using Stub data!");
      return new TaskManagementServiceStub(this.authService).accceptChallenge(challengeId, rewardOption, userUuid);
    }
    return new TaskManagementServiceNetwork(this.authService).accceptChallenge(challengeId, rewardOption, userUuid);
  }

  submitChallenge(challengeId) {
    if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
      console.info("window.TASK_API_URL is not set! Using Stub data!");
      return new TaskManagementServiceStub(this.authService).submitChallenge(challengeId);
    }
    return new TaskManagementServiceNetwork(this.authService).submitChallenge(challengeId);
  }

  getChallengeDetails(challengeIdOrSlug, region) {
    if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
      console.info("window.TASK_API_URL is not set! Using Stub data!");
      return new TaskManagementServiceStub(this.authService).getChallengeDetails(challengeIdOrSlug, region);
    }
    return new TaskManagementServiceNetwork(this.authService).getChallengeDetails(challengeIdOrSlug, region);
  }

  getChallengeList(accepted, region) {
    if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
      console.info("window.TASK_API_URL is not set! Using Stub data!");
      return new TaskManagementServiceStub(this.authService).getChallengeList(accepted, region);
    }
    return new TaskManagementServiceNetwork(this.authService).getChallengeList(accepted, region);
  }

  getSponsoredCampaignsList() {
    if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
      console.info("window.TASK_API_URL is not set! Using Stub data!");
      return new TaskManagementServiceStub(this.authService).getSponsoredCampaignsList();
    }
    return new TaskManagementServiceNetwork(this.authService).getSponsoredCampaignsList();
  }

  getSponsoredCampaignStatistics(campaignId) {
    if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
      console.info("window.TASK_API_URL is not set! Using Stub data!");
      return new TaskManagementServiceStub(this.authService).getSponsoredCampaignStatistics(campaignId);
    }
    return new TaskManagementServiceNetwork(this.authService).getSponsoredCampaignStatistics(campaignId);
  }

  submitTask(taskId, commitHash) {
    if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
      console.info("window.TASK_API_URL is not set! Using Stub data!");
      return new TaskManagementServiceStub(this.authService).submitTask(taskId, commitHash);
    }
    return new TaskManagementServiceNetwork(this.authService).submitTask(taskId, commitHash);
  }

  getDataOfMultipleChallenges(challengeIds) {
    if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
      console.info("window.TASK_API_URL is not set! Using Stub data!");
      return new TaskManagementServiceStub(this.authService).getDataOfMultipleChallenges(challengeIds);
    }
    return new TaskManagementServiceNetwork(this.authService).getDataOfMultipleChallenges(challengeIds);
  }

  getSolvedChallenge(userId) {
    if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
      console.info("window.TASK_API_URL is not set! Using Stub data!");
      return new TaskManagementServiceStub(this.authService).getSolvedChallenge(userId);
    }
    return new TaskManagementServiceNetwork(this.authService).getSolvedChallenge(userId);
  }

  downloadChallengeSolutionZip(acceptedChallengeUuid) {
    if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
      console.info("window.TASK_API_URL is not set! Using Stub data!");
      return new TaskManagementServiceStub(this.authService).downloadChallengeSolutionZip(acceptedChallengeUuid);
    }
    return new TaskManagementServiceNetwork(this.authService).downloadChallengeSolutionZip(acceptedChallengeUuid);
  }

  getDataOfMultipleChallengeTasks(taskIds) {
    if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
      console.info("window.TASK_API_URL is not set! Using Stub data!");
      return new TaskManagementServiceStub(this.authService).getDataOfMultipleChallengeTasks(taskIds);
    }
    return new TaskManagementServiceNetwork(this.authService).getDataOfMultipleChallengeTasks(taskIds);
  }

  getSolvedChallengesForPublicUserIds(publicUsersIds) {
    if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
      console.info("window.TASK_API_URL is not set! Using Stub data!");
      return new TaskManagementServiceStub(this.authService).getSolvedChallengesForPublicUserIds(publicUsersIds);
    }
    return new TaskManagementServiceNetwork(this.authService).getSolvedChallengesForPublicUserIds(publicUsersIds);
  }

  getAcceptedChallengeDetails(acceptedChallengeUuid) {
    if (typeof window.TASK_API_URL === "undefined" || window.TASK_API_URL === "") {
      console.info("window.TASK_API_URL is not set! Using Stub data!");
      return new TaskManagementServiceStub(this.authService).getAcceptedChallengeDetails(acceptedChallengeUuid);
    }
    return new TaskManagementServiceNetwork(this.authService).getAcceptedChallengeDetails(acceptedChallengeUuid);
  }
}
