import { CompanyServiceStub } from "./CompanyServiceStub";
import { CompanyServiceNetwork } from "./CompanyServiceNetwork";

export default class CompanyService {
  constructor(auth) {
    this.authService = auth;
  }

  getSponsoredChallenges() {
    if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
      console.info("window.COMPANY_API_URL is not set! Using Stub data!");
      return new CompanyServiceStub(this.authService).getSponsoredChallenges();
    }
    return new CompanyServiceNetwork(this.authService).getSponsoredChallenges();
  }

  getSponsoredChallengeDetails(sponsoredChallengeId) {
    if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
      console.info("window.COMPANY_API_URL is not set! Using Stub data!");
      return new CompanyServiceStub(this.authService).getSponsoredChallengeDetails(sponsoredChallengeId);
    }
    return new CompanyServiceNetwork(this.authService).getSponsoredChallengeDetails(sponsoredChallengeId);
  }

  getSponsoredLead(leadId) {
    if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
      console.info("window.COMPANY_API_URL is not set! Using Stub data!");
      return new CompanyServiceStub(this.authService).getSponsoredLead(leadId);
    }
    return new CompanyServiceNetwork(this.authService).getSponsoredLead(leadId);
  }

  updateSponsoredLead(leadId, communicationState) {
    if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
      console.info("window.COMPANY_API_URL is not set! Using Stub data!");
      return new CompanyServiceStub(this.authService).updateSponsoredLead(leadId, communicationState);
    }
    return new CompanyServiceNetwork(this.authService).updateSponsoredLead(leadId, communicationState);
  }

  updatePoolLead(leadId, communicationState) {
    if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
      console.info("window.COMPANY_API_URL is not set! Using Stub data!");
      return new CompanyServiceStub(this.authService).updatePoolLead(leadId, communicationState);
    }
    return new CompanyServiceNetwork(this.authService).updatePoolLead(leadId, communicationState);
  }

  getEmployeeByUserId() {
    if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
      console.info("COMPANY_API_URL is not set! Using Stub data!");
      return new CompanyServiceStub(this.authService).getEmployeeByUserId();
    }
    return new CompanyServiceNetwork(this.authService).getEmployeeByUserId();
  }

  getEmployeeByUserIdPromise() {
    if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
      console.info("COMPANY_API_URL is not set! Using Stub data!");
      return new CompanyServiceStub(this.authService).getEmployeeByUserIdPromise();
    }
    return new CompanyServiceNetwork(this.authService).getEmployeeByUserIdPromise();
  }

  getPoolChallenges() {
    if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
      console.info("window.COMPANY_API_URL is not set! Using Stub data!");
      return new CompanyServiceStub(this.authService).getPoolChallenges();
    }
    return new CompanyServiceNetwork(this.authService).getPoolChallenges();
  }

  getPoolChallengeDetails(poolChallengeId) {
    if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
      console.info("window.COMPANY_API_URL is not set! Using Stub data!");
      return new CompanyServiceStub(this.authService).getPoolChallengeDetails(poolChallengeId);
    }
    return new CompanyServiceNetwork(this.authService).getPoolChallengeDetails(poolChallengeId);
  }

  getPoolLead(leadId) {
    if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
      console.info("window.COMPANY_API_URL is not set! Using Stub data!");
      return new CompanyServiceStub(this.authService).getPoolLead(leadId);
    }
    return new CompanyServiceNetwork(this.authService).getPoolLead(leadId);
  }

  getCompanyProfile(companyId) {
    if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
      console.info("window.COMPANY_API_URL is not set! Using Stub data!");
      return new CompanyServiceStub(this.authService).getCompanyProfile(companyId);
    }
    return new CompanyServiceNetwork(this.authService).getCompanyProfile(companyId);
  }

  updateCompanyProfile(profileId, profile) {
    if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
      console.info("window.COMPANY_API_URL is not set! Using Stub data!");
      return new CompanyServiceStub(this.authService).updateCompanyProfile(profileId);
    }
    return new CompanyServiceNetwork(this.authService).updateCompanyProfile(profileId, profile);
  }

  registerCompany(data, registration_code = null) {
    if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
      console.info("window.COMPANY_API_URL is not set! Using Stub data!");
      return new CompanyServiceStub(this.authService).registerCompany(data, registration_code);
    }
    return new CompanyServiceNetwork(this.authService).registerCompany(data, registration_code);
  }

  requestVacancyOffer(data) {
    if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
      console.info("window.COMPANY_API_URL is not set! Using Stub data!");
      return new CompanyServiceStub(this.authService).requestVacancyOffer(data);
    }
    return new CompanyServiceNetwork(this.authService).requestVacancyOffer(data);
  }

  getRegistrationCode(registrationCode) {
    if (typeof window.COMPANY_API_URL === "undefined" || window.COMPANY_API_URL === "") {
      console.info("window.COMPANY_API_URL is not set! Using Stub data!");
      return new CompanyServiceStub(this.authService).getRegistrationCode(registrationCode);
    }
    return new CompanyServiceNetwork(this.authService).getRegistrationCode(registrationCode);
  }
}
