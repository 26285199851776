import { Cache, responseHandler } from "../Utilities";
import {
  ApplyForBeingACreatorResult,
  CoinsLeaderBoard,
  UserProfilesData,
  UserProfileChangeResult,
  UserProfileResult,
  UserRegistration,
  PublicUserProfileResult,
  MultiplePublicUserProfileDataResult,
} from "./UserServiceResponses";

const cache = new Cache();

function getFromCacheIfThere(withoutCache, key, result) {
  if (withoutCache) {
    cache.pushToCache(key, null);
  }

  let valueFromCache = cache.getValueFromCache(key);
  if (valueFromCache) {
    return valueFromCache;
  }

  cache.pushToCache(key, result);
  return null;
}

export class UserServiceNetwork {
  constructor(auth) {
    this.authService = auth;
  }

  getUserProfile() {
    let result = new UserProfileResult();
    fetch(window.USER_API_URL + "/api/user_profile/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.user_profile = json;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  changeUserProfile(data) {
    let result = new UserProfileChangeResult();
    fetch(window.USER_API_URL + "/api/user_profile/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(responseHandler)
      .then((json) => {
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getProfileDataOfMultipleUsers(user_ids) {
    let result = new UserProfilesData();
    fetch(window.USER_API_URL + "/api/user_profile/get_data_for_user_ids/", {
      headers: this.authService.getAuthorizationHeader(),
      body: JSON.stringify({ user_ids: user_ids }),
      method: "POST",
    })
      .then(responseHandler)
      .then((json) => {
        result.userProfilesData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.error = error;
        result.loaded = true;
      });
    return result;
  }

  postUserRegistration(data) {
    let result = new UserRegistration();
    fetch(window.USER_API_URL + "/api/user_profile/registration/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(responseHandler)
      .then((json) => {
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getCoinsLeaderBoard() {
    let result = new CoinsLeaderBoard();
    fetch(window.USER_API_URL + "/api/coin_leaderboard/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.leaderBoard = json.leaderboard;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  applyForBeingACreator(creatorInterest) {
    let result = new ApplyForBeingACreatorResult();
    fetch(window.USER_API_URL + "/api/user_profile/apply_for_being_a_creator/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify({ creatorInterest: creatorInterest }),
    })
      .then(responseHandler)
      .then((json) => {
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getPublicUserProfile(userId) {
    let result = new PublicUserProfileResult();
    fetch(window.USER_API_URL + "/public_api/user_profile_public/" + userId + "/")
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getDataForPublicUserIds(userIds) {
    let result = new MultiplePublicUserProfileDataResult();
    fetch(window.USER_API_URL + "/api/user_profile/get_data_for_public_user_ids/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify({ publicUserIds: userIds }),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }
}
