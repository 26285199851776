import {
  SetCandidateNote,
  UpdateCandidateFlags,
  Vacancies,
} from "@/services/interact/InteractsServiceResponses";
import { responseHandler } from "@/services/Utilities";

export default class InteractsServiceNetwork {
  constructor(auth) {
    this.authService = auth;
  }

  getAllCandidatesForCompany(companyId) {
    let result = new Vacancies();
    let url =
      window.INTERACTS_API_URL +
      "/api/company/" +
      companyId +
      "/candidate_list/";
    fetch(url, {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  setCandidateNote(companyId, candidate) {
    let result = new SetCandidateNote();
    let url =
      window.INTERACTS_API_URL +
      "/api/company/" +
      companyId +
      "/candidate/" +
      candidate.publicUserId +
      "/set_candidate_note/";
    fetch(url, {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify({
        content: candidate.note.content,
      }),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  updateInterestedCandidateFlags(companyId, candidate) {
    let result = new UpdateCandidateFlags();
    let url =
      window.INTERACTS_API_URL +
      "/api/company/" +
      companyId +
      "/candidate/" +
      candidate.publicUserId +
      "/update_candidate_flags/";
    fetch(url, {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify({
        isBookmarked: candidate.isBookmarked,
        isArchived: candidate.isArchived,
        isSeen: candidate.seen,
      }),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  updateCandidateFlags(interactJobOfferId, candidate) {
    let result = new UpdateCandidateFlags();
    let url =
      window.INTERACTS_API_URL +
      "/api/vacancy/" +
      interactJobOfferId +
      "/candidate/" +
      candidate.publicUserId +
      "/update_candidate_flags/";
    fetch(url, {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify({
        isBookmarked: candidate.isBookmarked,
        isArchived: candidate.isArchived,
        isSeen: candidate.seen,
      }),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  bulkUpdateInterestedCandidateFlags(companyId, data) {
    let result = new UpdateCandidateFlags();
    let url = window.INTERACTS_API_URL + "/api/company/" + companyId + "/bulk_update_candidate_flags/";
    fetch(url, {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  bulkUpdateCandidateFlags(interactJobOfferId, data) {
    let result = new UpdateCandidateFlags();
    let url = window.INTERACTS_API_URL + "/api/vacancy/" + interactJobOfferId + "/bulk_update_candidate_flags/";
    fetch(url, {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }
}
