export default class SocketStub {
  constructor(props = {}) {
    this.events = {};
    this.emitted = [];
    Object.assign(this, props);
  }

  on(event, handler) {
    this.events[event] = handler;
  }

  emit(...args) {
    this.emitted = [...this.emitted, args];
  }

  simulate(event, payload) {
    this.events[event](payload);
  }

  clear() {
    this.emitted = [];
  }
}

export class NotificationsServiceStub {
  constructor(auth) {
    this.authService = auth;
  }

  getNewMessageNotificationSocket() {
    let socketStub = new SocketStub();
    /* Use only for notification testing
    const userIdStub = this.authService.getUserID();
    setInterval(function() {
      let channelId = "channel00" + String(Math.ceil(Math.random() * 5));
      socketStub.simulate(`notifications_message_${userIdStub}_new-message`, JSON.stringify({ "channelId": channelId, }));
    }, 5000);
    */
    return socketStub;
  }
}
