import { responseHandler } from "../Utilities";
import {
  SponsoredChallengesResult,
  SponsoredChallengeDetails,
  SponsoredLeadResult,
  UpdateSponsoredLeadResult,
  EmployeeByUserIdResult,
  PoolChallengesResult,
  UpdatePoolLeadResult,
  CompanyProfileResult,
  UpdateCompanyProfileResult,
  RegisterCompanyResult,
  RequestVacancyOffer,
  RegistrationCodeResult,
} from "./CompanyServiceResponses";

export class CompanyServiceNetwork {
  constructor(auth) {
    this.authService = auth;
  }

  getSponsoredChallenges() {
    let result = new SponsoredChallengesResult();
    fetch(window.COMPANY_API_URL + "/api/company/sponsored_challenges/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getSponsoredChallengeDetails(sponsoredChallengeId) {
    let result = new SponsoredChallengeDetails();
    fetch(window.COMPANY_API_URL + "/api/company/sponsored_challenges/" + sponsoredChallengeId + "/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getSponsoredLead(leadId) {
    let result = new SponsoredLeadResult();
    fetch(window.COMPANY_API_URL + "/api/company/sponsored_lead/" + leadId + "/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  updateSponsoredLead(leadId, communicationState) {
    let result = new UpdateSponsoredLeadResult();
    fetch(window.COMPANY_API_URL + "/api/company/sponsored_lead/" + leadId + "/update_sponsored_lead/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify({ communicationState: communicationState }),
    })
      .then(responseHandler)
      .then((json) => {
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  updatePoolLead(leadId, communicationState) {
    let result = new UpdatePoolLeadResult();
    fetch(window.COMPANY_API_URL + "/api/company/pool_lead/" + leadId + "/update_pool_lead/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify({ communicationState: communicationState }),
    })
      .then(responseHandler)
      .then((json) => {
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getEmployeeByUserId() {
    let result = new EmployeeByUserIdResult();
    fetch(window.COMPANY_API_URL + "/api/employee/get_employee_by_user_id/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.loaded = true;
        result.apiData = json.data;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getEmployeeByUserIdPromise() {
    let result = new EmployeeByUserIdResult();
    return fetch(window.COMPANY_API_URL + "/api/employee/get_employee_by_user_id/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.loaded = true;
        result.apiData = json.data;
        return result;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
        return result;
      });
  }

  getPoolChallenges() {
    let result = new PoolChallengesResult();
    fetch(window.COMPANY_API_URL + "/api/company/pool_challenges/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getPoolChallengeDetails(poolChallengeId) {
    let result = new SponsoredChallengeDetails();
    fetch(window.COMPANY_API_URL + "/api/company/pool_challenges/" + poolChallengeId + "/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getPoolLead(leadId) {
    let result = new SponsoredLeadResult();
    fetch(window.COMPANY_API_URL + "/api/company/pool_lead/" + leadId + "/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getCompanyProfile(companyId) {
    let result = new CompanyProfileResult();
    fetch(window.COMPANY_API_URL + "/api/profiles/" + companyId + "/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  updateCompanyProfile(profileId, profile) {
    let result = new UpdateCompanyProfileResult();
    fetch(window.COMPANY_API_URL + "/api/profiles/update/" + profileId + "/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify({ ...profile }),
    })
      .then(responseHandler)
      .then((json) => {
        result.loaded = true;
      })
      .catch((error) => {
        // console.error(message, fields);
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  registerCompany(data, registration_code = null) {
    let result = new RegisterCompanyResult();
    let query = "";
    if (registration_code) {
      query = "?registration_code=" + registration_code;
    }
    fetch(window.COMPANY_API_URL + "/api/company/register_company/" + query, {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify({ ...data }),
    })
      .then(responseHandler)
      .then((json) => {
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  requestVacancyOffer(data) {
    let result = new RequestVacancyOffer();
    fetch(window.COMPANY_API_URL + "/api/company/request_vacancy_offer/create/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify({ ...data }),
    })
      .then(responseHandler)
      .then((json) => {
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getRegistrationCode(registrationCode) {
    let result = new RegistrationCodeResult();
    fetch(window.COMPANY_API_URL + "/api/registration_code/" + registrationCode + "/", {
      method: "GET",
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }
}
