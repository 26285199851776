import APIResult from "@/services/APIResult";

export class CoinsLeaderBoard {
  constructor() {
    this.leaderBoard = {};
    this.loaded = false;
    this.error = null;
  }
}

export class UserProfilesData {
  constructor() {
    this.userProfilesData = {};
    this.loaded = false;
    this.error = null;
  }
}

export class UserProfileChangeResult {
  constructor() {
    this.error = null;
    this.loaded = false;
  }
}

export class ApplyForBeingACreatorResult {
  constructor() {
    this.error = null;
    this.loaded = false;
  }
}

export class UserProfileResult {
  constructor() {
    this.user_profile = {};
    this.error = null;
    this.loaded = false;
  }
}

export class UserRegistration {
  constructor() {
    this.error = null;
    this.loaded = false;
  }
}

export class PublicUserProfileResult {
  constructor() {
    this.apiData = {};
    this.error = null;
    this.loaded = false;
  }
}

export class MultiplePublicUserProfileDataResult extends APIResult {
  constructor() {
    super();
    this.apiData = [];
    this.error = null;
    this.loaded = false;
  }
}
