<template>
  <button class="btn toggle-sidebar-button" type="button" @click="sendToggleSidebarEvent()">
    <i :class="icon" />
  </button>
</template>

<script>
import { eventBus } from "@/eventbus";

export default {
  name: "SideBarToggleButton",
  props: {
    icon: { type: String, default: "fa fa-bars" },
  },
  methods: {
    sendToggleSidebarEvent() {
      eventBus.$emit("toggleSidebar");
    },
  },
};
</script>

<style scoped>
.toggle-sidebar-button {
  border-radius: 0.5rem;
  z-index: 3;
  position: absolute;
  background-color: white;
}

.toggle-sidebar-button:hover {
  background-color: #dcdcdc;
  color: #2a2a2a;
}

.toggle-sidebar-button:focus {
  box-shadow: none;
}
</style>
