<template>
  <div class="modal" :class="{ in: isVisible, open: isVisible }" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-center"
            ><img
              style="max-width: 120px; margin: 2rem 0;"
              src="https://public-entwicklerheld.s3.eu-central-1.amazonaws.com/cookie.png"
          /></p>
          <h1 class="text-center" style="margin-bottom: 2rem;"><strong>Privacy</strong> first!</h1>
          <p
            >Wir verwenden für den Seitenbetrieb notwendige Cookies und optionale zur Erhebung anonymisierter
            Statistiken. Sie können selbst entscheiden, ob Sie diese nutzen möchten. Ihre Einstellungen können Sie
            jederzeit <a class="" href="https://entwicklerheld.de/cookie_policy/" target="_blank">hier</a> ändern.<br />
            Weitere Informationen finden Sie auch in unseren
            <a class="" href="https://entwicklerheld.de/data_privacy/" target="_blank"
              >Datenschutzschutzbestimmungen.</a
            ></p
          >
        </div>
        <div class="modal-footer">
          <button type="button" class="btn" @click="declineConsent">Ablehnen</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="acceptConsent"
            >Akzeptieren</button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CookieAcceptResult } from "@/services/cookie-consent/CookieConsentServiceResponses";
import { services } from "@/main";

export default {
  name: "CookieBar",
  props: {
    isVisible: { type: [Boolean], default: false },
  },
  data() {
    return {
      cookieConsentApiRequest: new CookieAcceptResult(),
    };
  },
  watch: {
    "cookieConsentApiRequest.loaded": function() {
      // ToDo: Send an event instead of reloading the page
      this.$router.go();
    },
  },
  methods: {
    declineConsent() {
      this.cookieConsentApiRequest = services.cookieConsentService.decline();
    },
    acceptConsent() {
      this.cookieConsentApiRequest = services.cookieConsentService.accept();
    },
  },
};
</script>

<style scoped>
.open {
  display: block;
}

.modal.open {
  z-index: 2300;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content a {
  color: #2f9798;
  cursor: pointer;
}

.btn-secondary {
  background-color: rgb(50, 153, 152);
  border-color: rgb(50, 153, 152);
}
.btn-secondary:hover {
  background-color: rgb(40, 122, 122);
  border-color: rgb(40, 122, 122);
}
</style>
