import { QualityServiceStub } from "./QualityServiceStub";
import { QualityServiceNetwork } from "./QualityServiceNetwork";
import { Scores } from "@/services/quality/QualityServiceResponses";

export default class QualityService {
  constructor(auth) {
    this.authService = auth;
  }

  getQualityReport(challengeId, userId) {
    if (typeof window.QUALITY_API_URL === "undefined" || window.QUALITY_API_URL === "") {
      console.info("window.QUALITY_API_URL is not set! Using Stub data!");
      return new QualityServiceStub(this.authService).getQualityReport(challengeId, userId);
    }
    return new QualityServiceNetwork(this.authService).getQualityReport(challengeId, userId);
  }

  getQualityReportByUuid(acceptedChallengeUuid) {
    if (typeof window.QUALITY_API_URL === "undefined" || window.QUALITY_API_URL === "") {
      console.info("window.QUALITY_API_URL is not set! Using Stub data!");
      return new QualityServiceStub(this.authService).getQualityReportByUuid(acceptedChallengeUuid);
    }
    return new QualityServiceNetwork(this.authService).getQualityReportByUuid(acceptedChallengeUuid);
  }

  getScores(challengeId) {
    if (typeof window.QUALITY_API_URL === "undefined" || window.QUALITY_API_URL === "") {
      console.info("window.QUALITY_API_URL is not set! Using Stub data!");
      return new QualityServiceStub(this.authService).getScores(challengeId);
    }
    return new QualityServiceNetwork(this.authService).getScores(challengeId);
  }

  getHistoryReport(challengeId, userId, file, task) {
    if (typeof window.QUALITY_API_URL === "undefined" || window.QUALITY_API_URL === "") {
      console.info("window.QUALITY_API_URL is not set! Using Stub data!");
      return new QualityServiceStub(this.authService).getHistoryReport(challengeId, userId, file, task);
    }
    return new QualityServiceNetwork(this.authService).getHistoryReport(challengeId, userId, file, task);
  }

  getHistoryReportByUuid(acceptedChallengeUuid, file, task) {
    if (typeof window.QUALITY_API_URL === "undefined" || window.QUALITY_API_URL === "") {
      console.info("window.QUALITY_API_URL is not set! Using Stub data!");
      return new QualityServiceStub(this.authService).getHistoryReportByUuid(acceptedChallengeUuid, file, task);
    }
    return new QualityServiceNetwork(this.authService).getHistoryReportByUuid(acceptedChallengeUuid, file, task);
  }

  getMeasures(challengeId, userId) {
    if (typeof window.QUALITY_API_URL === "undefined" || window.QUALITY_API_URL === "") {
      console.info("window.QUALITY_API_URL is not set! Using Stub data!");
      return new QualityServiceStub(this.authService).getMeasures(challengeId, userId);
    }
    return new QualityServiceNetwork(this.authService).getMeasures(challengeId, userId);
  }

  getMeasuresByUuid(acceptedChallengeUuid) {
    if (typeof window.QUALITY_API_URL === "undefined" || window.QUALITY_API_URL === "") {
      console.info("window.QUALITY_API_URL is not set! Using Stub data!");
      return new QualityServiceStub(this.authService).getMeasuresByUuid(acceptedChallengeUuid);
    }
    return new QualityServiceNetwork(this.authService).getMeasuresByUuid(acceptedChallengeUuid);
  }
}
