import {
  Candidates,
  SetCandidateNote,
  UpdateCandidateFlags,
} from "@/services/interact/InteractsServiceResponses";

export class InteractsServiceStub {
  constructor(auth) {
    this.authService = auth;
  }

  getAllCandidatesForCompany(companyId) {
    let result = new Candidates();
    let date1 = new Date();
    let date2 = new Date();
    let date3 = new Date();
    let date4 = new Date();
    date1.setMinutes(date1.getMinutes() - 30);
    date2.setDate(date2.getDate() - 3);
    date3.setMonth(date3.getMonth() - 2);
    date3.setDate(date3.getDate() - 7);
    date4.setMonth(date4.getMonth() - 10);
    setTimeout(function () {
      result.apiData = [
        {
          publicUserId: "6296f40d95c3fdeb12216d525f44f36e",
          anonymizedUserName: "Amazing Maze",
          anonymizedPicture:
            "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:81ecec,f_png/e_outline:70,co_rgb:81ecec/v1597653561/eh-platform/user_avatars/2-002_Squid_Monster-Cartoony.svg",
          state: "HIRED",
          created: date1.toISOString(),
          starred: true,
          seen: false,
          isArchived: false,
          isBookmarked: false,
          onlyCompanySelected: true,
          note: {id: "46908147-7f35-42e1-acd6-89e065df4d0f", content: "Amazing"},
          cvFile: "https://polylith-public-files.s3.eu-central-1.amazonaws.com/CV_Example.png",
          region: "Bayern",
          profession: "Professional",
          itWorkExperience: null,
          solvedChallenges: [
            {
              challengeId: "1b20123e-0d49-4ea4-a213-59e869675172",
              acceptedChallengeUuid: "b4fe4d81-7544-4823-aa20-cc066fa58107",
            },
          ],
          unsolvedChallengeIds: [],
          jobRequestIds: ["79bc4821-de44-4cf3-b4cc-aa12dc65ba32"],
          jobOffers: {
            "12345": {
              title: "Full Stack Developer PHP/TYPO3 (w/m/d)",
            },
            "1234": {
              title: "Full Stack Python",
              isActive: true,
            },
          },
        },
        {
          publicUserId: "f1d37e65a3b7d3687f6afef3c7029a14",
          state: "CONTACTED",
          created: date2.toISOString(),
          starred: true,
          seen: false,
          isArchived: false,
          isBookmarked: false,
          onlyCompanySelected: true,
          note: {},
          anonymizedUserName: "Lazy Loris",
          anonymizedPicture:
            "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:d63031,f_png/e_outline:70,co_rgb:d63031/v1597653561/eh-platform/user_avatars/9-009_Happy_Cat-Retro.svg",
          region: "Buxtehude",
          profession: "Student/in",
          itWorkExperience: 2,
          solvedChallenges: [
            {
              challengeId: "e918f361-abe7-4187-a89a-8be94bd4f64b",
              acceptedChallengeUuid: "650d2060-3f6e-4272-878b-aa16847da7fd",
            },
            {
              challengeId: "e6458744-d7bf-4fa1-8689-ef1643937339",
              acceptedChallengeUuid: "7c5b72f8-f73a-42b9-aff0-8b833993513e",
            },
            {
              challengeId: "58c45208-c9cc-474e-94d4-ced187b077e0",
              acceptedChallengeUuid: "650d2060-3f6e-4272-878b-aa16847da7fd",
            },
            {
              challengeId: "e6458744-d7bf-4fa1-8689-ef1643937339",
              acceptedChallengeUuid: "7c5b72f8-f73a-42b9-aff0-8b833993513h",
            },
            {
              challengeId: "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
              acceptedChallengeUuid: "650d2060-3f6e-4272-878b-aa16847da7fd",
            },
          ],
          unsolvedChallengeIds: [],
          jobRequestIds: ["17dd4756-db91-46bc-a2e5-cee777a0f3d0", "590c1e6a-4bcf-45c7-ad33-ef7b410cacbc"],
          jobOffers: {
            "12345": {
              title: "Full Stack Developer PHP/TYPO3 (w/m/d)",
            },
          },
        },
        {
          publicUserId: "6296f40d95c3fdeb12216d525f44f362",
          anonymizedUserName: "Fuzzy Flipper",
          anonymizedPicture:
            "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:74b9ff,f_png/e_outline:70,co_rgb:74b9ff/v1597653561/eh-platform/user_avatars/25-025_St_Bernard-Retro.svg",
          state: "DECLINED",
          created: date3.toISOString(),
          starred: true,
          seen: false,
          isArchived: false,
          isBookmarked: false,
          onlyCompanySelected: true,
          note: {},
          cvFile: "",
          region: "Sauerland",
          profession: "Professional",
          itWorkExperience: 7,
          solvedChallenges: [
            {
              challengeId: "9c778212-5cf0-4b5d-b2b5-49bea7eb1d53",
              acceptedChallengeUuid: "650d2060-3f6e-4272-878b-aa16847da7fd",
            },
            {
              challengeId: "0b465303-ae29-44fc-9d17-e61732585a01",
              acceptedChallengeUuid: "28dc147b-34f5-4b8f-be93-4d3ecbc6611a",
            },
          ],
          unsolvedChallengeIds: [],
          jobRequestIds: ["3bbf2c27-f454-4f0b-9c98-874f06d30286"],
          jobOffers: {
            "1234": {
              title: "Full Stack Python",
              isActive: true,
            },
          },
        },
        {
          publicUserId: "6296f40d95c3fdeb12216d525f44f36f",
          anonymizedUserName: "Homer Jay",
          anonymizedPicture:
            "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:ffeaa7,f_png/e_outline:70,co_rgb:ffeaa7/v1597653561/eh-platform/user_avatars/9-009_Horned_Devil-Yellow.svg",
          state: "HIRED",
          created: date4.toISOString(),
          starred: true,
          seen: false,
          isArchived: false,
          isBookmarked: false,
          onlyCompanySelected: true,
          note: {},
          cvFile: "https://polylith-public-files.s3.eu-central-1.amazonaws.com/CV_Example.png",
          region: "Schlumpfhausen",
          profession: "Professional",
          itWorkExperience: 3,
          solvedChallenges: [
            {
              challengeId: "1b20123e-0d49-4ea4-a213-59e869675172",
              acceptedChallengeUuid: "b4fe4d81-7544-4823-aa20-cc066fa58107",
            },
          ],
          unsolvedChallengeIds: [],
          jobRequestIds: [],
          jobOffers: {},
        },
        {
          publicUserId: "6296f40d95c3fdeb122167775f44f36a",
          anonymizedUserName: "Nice Wozniak",
          anonymizedPicture:
            "https://res.cloudinary.com/dspi5zyaa/image/upload/w_300,h_300,b_rgb:23e32f,f_png/e_outline:70,co_rgb:23e32f/v1597653561/eh-platform/user_avatars/69-069_Poodle-Cartoony.svg",
          state: "HIRED",
          created: date4.toISOString(),
          starred: false,
          seen: false,
          isArchived: false,
          isBookmarked: false,
          onlyCompanySelected: true,
          note: {},
          cvFile: "https://polylith-public-files.s3.eu-central-1.amazonaws.com/CV_Example.png",
          region: "Schlumpfhausen",
          profession: "Professional",
          itWorkExperience: null,
          solvedChallenges: [
            {
              challengeId: "1b20123e-0d49-4ea4-a213-59e869675172",
              acceptedChallengeUuid: "b4fe4d81-7544-4823-aa20-cc066fa58107",
            },
          ],
          unsolvedChallengeIds: [],
          jobRequestIds: [],
          jobOffers: {},
        },
      ];
      result.loaded = true;
    }, Math.random() * 1500);
    return result;
  }

  setCandidateNote(companyId, candidate) {
    let result = new SetCandidateNote();
    setTimeout(function () {
      result.apiData = {};
      result.loaded = true;
      //result.error = true;
    }, Math.random() * 1500 + 2000);
    return result;
  }

  updateInterestedCandidateFlags(companyId, candidate) {
    let result = new UpdateCandidateFlags();
    setTimeout(function () {
      result.apiData = {};
      result.loaded = true;
    }, Math.random() * 1500);
    return result;
  }

  updateCandidateFlags(interactJobOfferId, candidate) {
    let result = new UpdateCandidateFlags();
    setTimeout(function () {
      result.apiData = {};
      result.loaded = true;
    }, Math.random() * 1500);
    return result;
  }

  bulkUpdateInterestedCandidateFlags(companyId, data) {
    let result = new UpdateCandidateFlags();
    setTimeout(function () {
      result.apiData = {};
      result.loaded = true;
    }, Math.random() * 1500);
    return result;
  }

  bulkUpdateCandidateFlags(interactJobOfferId, data) {
    let result = new UpdateCandidateFlags();
    setTimeout(function () {
      result.apiData = {};
      result.loaded = true;
    }, Math.random() * 1500);
    return result;
  }
}
