<template>
  <nav id="sidebar" ref="sidebar" :key="forceUpdateCounter" class="sidebar active">
    <side-bar-toggle-button icon="fa fa-times" class="side-bar-close-button d-sm-block d-md-none" />
    <div class="progress-component wave-wrapper">
      <CCompanyProgress v-if="isRegistered" :company-id="companyId" />
      <div v-if="!isRegistered" class="user-info" @click="goToWelcome()">
        <img :alt="'Logo der letzten eingeloggten Firma: ' + company.name" class="profile-img" :src="company.logo" />
      </div>
      <svg
        id="Ebene_1"
        class="wave"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 250 52.9"
        style="enable-background:new 0 0 250 52.9;"
        xml:space="preserve"
      >
        <g>
          <defs>
            <rect id="SVGID_2_" width="250" height="57.2" />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlink:href="#SVGID_2_" style="overflow:visible;" />
          </clipPath>
          <path
            class="sidebarWave"
            d="M-7,23.4c47.1-12,84.6-11.7,110.7-9c45.2,4.6,60.7,17,97.7,10c37.9-7.1,54.7-26.3,68.9-17
		c13.6,8.9,18.3,39.5,5.7,58.2C259.7,89.7,223.6,81.1,98,84c-23,0.5-46-1-68.9,0.7c-5.3,0.4-13.8,0.1-30.8-0.3c-5.5-0.2-6.7-0.2-8-1
		C-20.3,77.2-12.8,44.1-7,23.4z"
          />
        </g>
      </svg>
    </div>

    <nav class="sidebar-navigation">
      <side-bar-toggle-button icon="fa fa-times" class="side-bar-close-button d-sm-block" />
      <div v-if="!isRegistered" class="nav-item-wrapper">
        <router-link :to="{ name: getRegistrationLink() }" class="nav-item" @click.native="sendToggleSidebarEvent()">
          <div class="nav-item-container"
            ><span class="fa fa-pencil-square-o icon"></span><span class="navbar-title-mobile">Registrieren</span></div
          >
        </router-link>
        <div class="navbar-tooltip">
          <span class="navbar-title">Registrieren</span>
        </div>
      </div>
      <div v-if="isRegistered">
        <div class="nav-item-wrapper">
          <router-link to="/profile" class="nav-item" @click.native="sendToggleSidebarEvent()">
            <div class="nav-item-container"
              ><span class="fa fa-user-circle-o icon"></span><span class="navbar-title-mobile">Profil</span></div
            >
          </router-link>
          <div class="navbar-tooltip">
            <span class="navbar-title">Profil</span>
          </div>
        </div>
        <div v-if="companyHasPaidState" class="nav-item-wrapper">
          <router-link :to="{ name: 'challenges' }" class="nav-item" @click.native="sendToggleSidebarEvent()">
            <div class="nav-item-container"
              ><span class="fa fa-check-square icon"></span
              ><span class="navbar-title-mobile">Challenges</span></div
            >
          </router-link>
          <div class="navbar-tooltip">
            <span class="navbar-title">Challenges</span>
          </div>
        </div>
        <div v-if="companyHasPaidState" class="nav-item-wrapper">
          <router-link :to="{ name: 'candidatesdashboard' }" class="nav-item" @click.native="sendToggleSidebarEvent()">
            <div class="nav-item-container"
              ><span class="fa fa-users icon"></span><span class="navbar-title-mobile">Talents Dashboard</span></div
            >
          </router-link>
          <div class="navbar-tooltip">
            <span class="navbar-title">Talents</span>
          </div>
        </div>
      </div>
      <div v-if="!isAuthenticated" class="nav-item-wrapper">
        <div class="nav-item" @click="login">
          <div class="nav-item-container"
            ><span class="fa fa-sign-in icon"></span><span class="navbar-title-mobile">Login</span></div
          >
        </div>
        <div class="navbar-tooltip">
          <span class="navbar-title">Login</span>
        </div>
      </div>
      <hr v-if="isAuthenticated" />
      <div v-if="isAuthenticated" class="nav-item-wrapper">
        <a target="_blank" class="nav-item" href="https://platform.entwicklerheld.de">
          <div class="nav-item-container">
            <span class="fa fa-code icon"></span>
            <span class="navbar-title-mobile">zur Coding-Plattform</span>
          </div>
        </a>
        <div class="navbar-tooltip">
          <span class="navbar-title">zur Coding-Plattform</span>
        </div>
      </div>
      <div v-if="isAuthenticated" class="nav-item-wrapper">
        <div class="nav-item" @click="logout">
          <div class="nav-item-container"
            ><span class="fa fa-sign-out icon"></span><span class="navbar-title-mobile">Logout</span></div
          >
        </div>
        <div class="navbar-tooltip">
          <span class="navbar-title">Logout</span>
        </div>
      </div>
    </nav>
    <div class="nav-footer">
      <a target="_blank" href="https://entwicklerheld.de/corporate/">
        <img
          class="footer-logo"
          src="https://res.cloudinary.com/dspi5zyaa/image/upload/v1618482144/eh-platform/logos/logo-eh-grey.png"/></a
    ></div>
  </nav>
</template>

<script>
import { services } from "@/main";
import { eventBus } from "@/eventbus";
import SideBarToggleButton from "@/components/base/SideBarToggleButton";
import CCompanyProgress from "@/components/profile/CCompanyProgress";
import { navigateTo } from "@/utils/routing";
import { isRegistrationCodePresent } from "@/utils/registrationCode";

const { getRegistrationCode } = require("@/utils/registrationCode");

export default {
  components: { SideBarToggleButton, CCompanyProgress },
  data() {
    return {
      companyId: null,
      employeeRequest: { loaded: false, error: null },
      registrationCodeRequest: { loaded: false, error: null },
      forceUpdateCounter: 0,
      isAuthenticated: services.authenticationService.isAuthenticated(),
      companyHasPaidState: services.authenticationService.isPayingCustomer(),
      company: {
        name: "EntwicklerHeld GmbH",
        logo: "https://res.cloudinary.com/dspi5zyaa/image/upload/v1613052889/eh-platform/logos/eh-logo.png",
      },
    };
  },
  computed: {
    isRegistered() {
      if (!this.employeeRequest.loaded || this.employeeRequest.error) {
        return false;
      }
      return this.employeeRequest.apiData.isRegistered;
    },
    isCompanyActive() {
      if (!this.employeeRequest.loaded || this.employeeRequest.error) {
        return false;
      }

      return this.employeeRequest.apiData.isCompanyActive;
    },
  },
  watch: {
    "employeeRequest.loaded": function() {
      if (!this.employeeRequest.loaded || this.employeeRequest.error) {
        return;
      }
      if (!this.employeeRequest.apiData.company || !this.employeeRequest.apiData.company.id) {
        return;
      }

      this.companyId = this.employeeRequest.apiData.company.id;

      if (!this.companyHasPaidState) {
        return;
      }

      this.companyId = this.employeeRequest.apiData.company.id;
    },
    "registrationCodeRequest.loaded": function() {
      if (!this.registrationCodeRequest.loaded || this.registrationCodeRequest.error) {
        return;
      }

      this.company = {
        name: this.registrationCodeRequest.apiData.name,
        logo: this.registrationCodeRequest.apiData.logo,
      };
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.employeeRequest = services.companyService.getEmployeeByUserId();
    } else {
      let companyInformation = this.getCompanyInformationFromCookie("CompanyInformation");
      if (companyInformation !== null && companyInformation.includes(";")) {
        let information = companyInformation.split(";");
        this.company = {
          name: information[0],
          logo: information[1],
        };
      }
    }

    if (isRegistrationCodePresent(this.$route.query)) {
      this.registrationCodeRequest = services.companyService.getRegistrationCode(
        getRegistrationCode(this.$route.query)
      );
    }

    eventBus.$on("userLoggedIn", () => {
      this.employeeRequest = services.companyService.getEmployeeByUserId();
      this.isAuthenticated = services.authenticationService.isAuthenticated();
      this.companyHasPaidState = services.authenticationService.isPayingCustomer();
    });
    eventBus.$on("toggleSidebar", () => {
      let activeClass = this.$refs.sidebar.classList.contains("active");
      if (activeClass) {
        this.$refs.sidebar.classList.remove("active");
        return;
      }
      this.$refs.sidebar.classList.add("active");
    });
    eventBus.$on("reloadProfileInformation", async () => {
      this.company.logo = "";
      this.forceUpdate();
    });
  },
  beforeDestroy() {
    eventBus.$off("toggleSidebar");
    eventBus.$off("userLoggedIn");
  },
  methods: {
    login() {
      // TODO: GET fullPath by name
      services.authenticationService.login({ fullPath: "/after_login_redirect" });
    },
    forceUpdate() {
      this.forceUpdateCounter += 1;
      this.company.logo = "https://res.cloudinary.com/dspi5zyaa/image/upload/v1613052889/eh-platform/logos/eh-logo.png";
    },
    logout: () => services.authenticationService.logout(),
    goToWelcome: function() {
      navigateTo(this.$router, "welcome");
    },
    getRegistrationLink: function() {
      if (isRegistrationCodePresent(this.$route.query)) {
        return "register-with-code";
      }

      return "register";
    },
    getCompanyInformationFromCookie(name) {
      name = name + "=";
      let allCookies = document.cookie.split(";");
      for (let i = 0; i < allCookies.length; i++) {
        let cookie = allCookies[i];
        while (cookie.charAt(0) === " ") cookie = cookie.substring(1, cookie.length);
        // decode from base64
        if (cookie.indexOf(name) === 0) return atob(cookie.substring(name.length, cookie.length));
      }
      return null;
    },
    sendToggleSidebarEvent() {
      eventBus.$emit("toggleSidebar");
    },
    closeSidebar: function() {
      let hasActiveClass = this.$refs.sidebar.classList.contains("active");
      if (!hasActiveClass) {
        this.$refs.sidebar.classList.add("active");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: relative; /* for IE 11 */
  position: sticky;
  position: -webkit-sticky;
  top: 0; /* needed for stickyness */
  box-shadow: 0 0px 14px rgba(0, 0, 0, 0.08) !important;
  margin-right: 0.7rem;

  max-width: 80px;
  min-width: 80px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .user-info {
    margin: 1rem auto;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .profile-img {
      border-radius: 50%;
      width: 70px;
      height: 70px;
      margin-bottom: 1rem;
      -o-object-fit: contain;
      object-fit: contain;
      border-radius: 50%;
      box-shadow: 0 10px 30px rgba(50, 50, 93, 0.1), 0 5px 10px rgba(0, 0, 0, 0.07);
      padding: 5px;
      background-color: white;
    }

    .company-name {
      margin: 0;
      text-align: center;
    }
  }

  &-navigation {
    color: #aaadbd;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;

    .nav-item {
      border-left: 4px solid transparent;
    }

    a.router-link-active {
      color: $eh-green;
      font-weight: 600;
      background-color: $eh-10;
      border-left: 4px solid $eh-green;
    }

    .nav-item {
      padding: 1rem 0;
      width: 100%;
      color: #aaadbc;
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        color: inherit;
        text-decoration: none;
      }
      &:hover {
        background-color: $eh-10;
        text-decoration: none;
        cursor: pointer;
      }

      &:hover:not(.router-link-active) {
        color: inherit;
      }

      &-container {
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      .icon {
        font-size: 1.6rem;
        min-width: 2rem;
      }
    }
  }
}

hr {
  width: 70px;
}

#sidebar {
  margin-left: 0;
  transition: margin-left 0.2s ease-in-out;
  z-index: 200;
}

#sidebar.active {
  margin-left: 0;
}

.navbar-title-mobile {
  display: none;
  white-space: nowrap;
}

.nav-item-wrapper {
  position: relative;
}
.nav-item:hover + .navbar-tooltip {
  min-width: 40px;
  z-index: 100;
  display: inline-block;
}

.navbar-tooltip {
  padding: 0.7rem 2rem;
  position: absolute;
  left: 95px;
  height: 45px;
  top: 6px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2) !important;
  display: none;
  background: $eh-light-green;
  z-index: -1;
  border-radius: 99px;
}

.navbar-title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: $eh-green;
  white-space: nowrap;
}

.side-bar-close-button {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
}

.user-info:hover {
  cursor: pointer;
}

.nav-footer {
  margin-top: auto;
  padding-bottom: 1.5rem;
}

.footer-logo {
  width: 50px;
  opacity: 0.5;
  margin-left: -0.2rem;
}

.progress-component {
  margin-bottom: 2rem;
  width: 100%;
  background-color: rgba(170, 173, 188, 0.17);
  padding-top: 3rem;
  padding-bottom: 4.5rem;
}

.sub-item {
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 5rem;
}

.company-reset {
  font-size: 10px;
}

.wave {
  position: absolute;
  bottom: 0;
}

.wave-wrapper {
  position: relative;
}

.sidebarWave {
  fill: #ffffff;
}

@media (max-width: 768px) {
  #sidebar {
    position: fixed;
    margin-left: 0;
    z-index: 1030;
    overflow-y: auto;
  }
  #sidebar.active {
    margin-left: -250px;
  }
  .sidebar {
    max-width: 250px;
    min-width: 250px;
  }
  .navbar-title-mobile {
    display: block;
  }
  .sidebar .user-info .profile-img {
    height: 110px;
    width: 110px;
  }
  .nav-item:hover + .navbar-tooltip {
    display: none;
  }
  .sidebar-navigation .nav-item {
    align-items: start;

    &-container {
      justify-content: left;
      text-align: left;
      margin-left: 1rem;
    }
  }

  hr {
    width: 200px;
  }
  .sidebar-navigation .nav-item .icon {
    margin-right: 1rem;
  }
}

@media (min-width: 769px) {
  .side-bar-close-button {
    display: none !important;
  }
  .sidebar {
    height: 100vh;
  }
}
</style>
