import InteractsServiceNetwork from "@/services/interact/InteractsServiceNetwork";
import { InteractsServiceStub } from "@/services/interact/InteractsServiceStub";

export default class InteractsService {
  constructor(auth) {
    this.authService = auth;
  }

  getAllCandidatesForCompany(companyId) {
    if (typeof window.INTERACTS_API_URL === "undefined" || window.INTERACTS_API_URL === "") {
      console.info("window.INTERACTS_API_URL is not set! Using Stub data!");
      return new InteractsServiceStub(this.authService).getAllCandidatesForCompany(companyId);
    }
    return new InteractsServiceNetwork(this.authService).getAllCandidatesForCompany(companyId);
  }

  setCandidateNote(companyId, candidate) {
    if (typeof window.INTERACTS_API_URL === "undefined" || window.INTERACTS_API_URL === "") {
      console.info("window.INTERACTS_API_URL is not set! Using Stub data!");
      return new InteractsServiceStub(this.authService).setCandidateNote(companyId, candidate);
    }
    return new InteractsServiceNetwork(this.authService).setCandidateNote(companyId, candidate);
  }

  updateInterestedCandidateFlags(companyId, candidate) {
    if (typeof window.INTERACTS_API_URL === "undefined" || window.INTERACTS_API_URL === "") {
      console.info("window.INTERACTS_API_URL is not set! Using Stub data!");
      return new InteractsServiceStub(this.authService).updateInterestedCandidateFlags(companyId, candidate);
    }
    return new InteractsServiceNetwork(this.authService).updateInterestedCandidateFlags(companyId, candidate);
  }

  updateCandidateFlags(interactJobOfferId, candidate) {
    if (typeof window.INTERACTS_API_URL === "undefined" || window.INTERACTS_API_URL === "") {
      console.info("window.INTERACTS_API_URL is not set! Using Stub data!");
      return new InteractsServiceStub(this.authService).updateCandidateFlags(interactJobOfferId, candidate);
    }
    return new InteractsServiceNetwork(this.authService).updateCandidateFlags(interactJobOfferId, candidate);
  }

  bulkUpdateInterestedCandidateFlags(companyId, data) {
    if (typeof window.INTERACTS_API_URL === "undefined" || window.INTERACTS_API_URL === "") {
      console.info("window.INTERACTS_API_URL is not set! Using Stub data!");
      return new InteractsServiceStub(this.authService).bulkUpdateInterestedCandidateFlags(companyId, data);
    }
    return new InteractsServiceNetwork(this.authService).bulkUpdateInterestedCandidateFlags(companyId, data);
  }

  bulkUpdateCandidateFlags(interactJobOfferId, data) {
    if (typeof window.INTERACTS_API_URL === "undefined" || window.INTERACTS_API_URL === "") {
      console.info("window.INTERACTS_API_URL is not set! Using Stub data!");
      return new InteractsServiceStub(this.authService).bulkUpdateCandidateFlags(interactJobOfferId, data);
    }
    return new InteractsServiceNetwork(this.authService).bulkUpdateCandidateFlags(interactJobOfferId, data);
  }
}
