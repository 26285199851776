import { MessageServiceStub } from "./MessageServiceStub";
import { MessageServiceNetwork } from "./MessageServiceNetwork";

export default class MessageService {
  constructor(auth) {
    this.authService = auth;
  }

  getChannels() {
    if (typeof window.MESSAGE_API_URL === "undefined" || window.MESSAGE_API_URL === "") {
      console.info("window.MESSAGE_API_URL is not set! Using Stub data!");
      return new MessageServiceStub(this.authService).getChannels();
    }
    return new MessageServiceNetwork(this.authService).getChannels();
  }

  getChannelDetails(channelId) {
    if (typeof window.MESSAGE_API_URL === "undefined" || window.MESSAGE_API_URL === "") {
      console.info("window.MESSAGE_API_URL is not set! Using Stub data!");
      return new MessageServiceStub(this.authService).getChannelDetails(channelId);
    }
    return new MessageServiceNetwork(this.authService).getChannelDetails(channelId);
  }

  sendMessage(channelId, content) {
    if (typeof window.MESSAGE_API_URL === "undefined" || window.MESSAGE_API_URL === "") {
      console.info("window.MESSAGE_API_URL is not set! Using Stub data!");
      return new MessageServiceStub(this.authService).sendMessage(channelId, content);
    }
    return new MessageServiceNetwork(this.authService).sendMessage(channelId, content);
  }

  saveDraftMessage(channelId, content) {
    if (window.MESSAGE_API_URL === "undefined" || window.MESSAGE_API_URL === "") {
      console.info("window.MESSAGE_API_URL is not set! Using Stub data!");
      return new MessageServiceStub(this.authService).saveDraftMessage(channelId, content);
    }
    return new MessageServiceNetwork(this.authService).saveDraftMessage(channelId, content);
  }

  getChannelId(jobRequestId, sponsoredLeadId) {
    if (typeof window.MESSAGE_API_URL === "undefined" || window.MESSAGE_API_URL === "") {
      console.info("window.MESSAGE_API_URL is not set! Using Stub data!");
      return new MessageServiceStub(this.authService).getChannelId(jobRequestId, sponsoredLeadId);
    }
    return new MessageServiceNetwork(this.authService).getChannelId(jobRequestId, sponsoredLeadId);
  }

  joinChannelForCompany(channelId) {
    if (typeof window.MESSAGE_API_URL === "undefined" || window.MESSAGE_API_URL === "") {
      console.info("window.MESSAGE_API_URL is not set! Using Stub data!");
      return new MessageServiceStub(this.authService).joinChannelForCompany(channelId);
    }
    return new MessageServiceNetwork(this.authService).joinChannelForCompany(channelId);
  }

}
