export class CookieAcceptResult {
  constructor() {
    this.error = null;
    this.loaded = false;
    this.apiData = {};
  }
}

export class CookieDeclineResult {
  constructor() {
    this.error = null;
    this.loaded = false;
    this.apiData = {};
  }
}
