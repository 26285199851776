<template>
  <div>
    <div class="wrapper text-center">
      <div class="d-block d-md-none headline">Nächster Schritt:</div>
      <div class="next-step-box hvr-grow" @click="jumpToStep()"
        ><strong v-if="nextStep">{{ nextStep.label }}</strong></div
      >
      <div class="next-step-button hvr-icon-forward" @click="skipStep()"
        >überspringen <i class="fa fa-chevron-circle-right hvr-icon"></i
      ></div>
    </div>
  </div>
</template>

<script>
import { companyProgressSteps } from "@/const";
import { navigateTo } from "@/utils/routing";

export default {
  name: "PProfileCompletionStep",
  props: {
    profileRequest: { type: Object, required: true },
    isPayingCustomer: { type: Boolean, required: true },
  },
  data: function() {
    return {
      index: 0,
    };
  },
  computed: {
    nextStep: function() {
      if (this.openSteps) {
        return this.openSteps[this.index];
      }
      return null;
    },
    openSteps: function() {
      if (!this.profileRequest.loaded || this.profileRequest.error) {
        return [];
      }
      return companyProgressSteps.filter(
        (step) => !step.completionHandler(this.profileRequest.apiData, this.isPayingCustomer)
      );
    },
  },
  methods: {
    jumpToStep: function() {
      if (this.$route.name === this.nextStep.route.name) {
        let elementId = this.nextStep.route.anchor;
        let element = document.getElementById(elementId);
        if (!element) {
          return;
        }
        element.scrollIntoView({ behavior: "smooth", block: "center" });

        let selectionElement = element.getElementsByClassName("selection");
        if (selectionElement.length > 0) {
          setTimeout(() => {
            selectionElement[0].focus();
          }, 1000);
        }
        return;
      }
      let hash = "";
      if (this.nextStep.route.anchor) {
        hash = "#" + this.nextStep.route.anchor;
      }
      navigateTo(this.$router, { name: this.nextStep.route.name, hash: hash });
    },
    skipStep: function() {
      if (this.index >= this.openSteps.length - 1) {
        this.index = 0;
        return;
      }
      this.index += 1;
    },
  },
};
</script>

<style scoped>
.next-step-button {
  font-size: 0.7rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.next-step-button i {
  color: rgba(34, 113, 113, 0.5);
}
.next-step-label {
}

.wrapper {
  position: relative;
}

.next-step-box {
  background-color: #8baca5;
  margin: 0.5rem 1.2rem;
  color: white;
  border-radius: 6px;
  padding: 0.5rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.headline {
  font-size: 0.9rem;
  font-weight: bold;
}

.next-step-box:hover,
.next-step-button:hover {
  cursor: pointer;
}

.hvr-icon-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}
.hvr-icon-forward .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-forward:hover .hvr-icon,
.hvr-icon-forward:focus .hvr-icon,
.hvr-icon-forward:active .hvr-icon {
  -webkit-transform: translateX(4px);
  transform: translateX(4px);
}

.hvr-grow {
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
