<template>
  <div id="app">
    <CookieBar :is-visible="!isCookieConsent" />
    <notifications group="updated-profile" position="bottom left" />
    <base-layout>
      <transition name="fade" mode="out-in">
        <router-view :key="this.$route.fullPath" />
      </transition>
    </base-layout>
  </div>
</template>

<script>
import "@/directives/closeable.js";
import { eventBus } from "@/eventbus";
import BaseLayout from "@/components/base/BaseLayout";
import CookieBar from "@/components/base/CookieBar";
import { isCookieConsentSet } from "@/services/Utilities";

export default {
  components: {
    CookieBar,
    BaseLayout,
  },
  data() {
    return {
      isCookieConsent: false,
    };
  },
  mounted() {
    this.isCookieConsent = isCookieConsentSet();
  },
};
</script>

<style lang="scss">
@import "./assets/custom.scss";

body {
  overflow-x: hidden;
  background-color: #fafafa;
}
.auth0-lock.auth0-lock .auth0-lock-header-logo {
  padding-top: 3rem;
  width: 100% !important;
  height: auto !important;
}

.auth0-lock-content-body-wrapper {
  padding-top: 3rem;
}

.vue-notification-group {
  z-index: 10002 !important;
}
/* classes for vue-js transition 'fade' */
.fade-enter-active, .fade-leave-active, .fade-enter-active + .footer, .fade-leave-active + .footer {
  transition-property: opacity;
  transition-duration: 0.1s;
}

.fade-enter, .fade-leave-active {
  opacity: 0;
}
</style>
