import { responseHandler } from "../Utilities.js";
import {
  GetChannelDetailsResult,
  GetChannelIdResult,
  GetChannelsResult, JoinChannelForCompanyResult,
  SaveDraftMessageResult,
  SendMessageResult,
} from "./MessageServiceResponses";

export class MessageServiceNetwork {
  constructor(auth) {
    this.authService = auth;
  }

   getJobOfferIds(channelId) {
     let result = new GetChannelDetailsResult();
    fetch(window.MESSAGE_API_URL + "/api/company/channel/" + channelId + "/get_job_offer_ids/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
   }

  getChannels() {
    let result = new GetChannelsResult();
    fetch(window.MESSAGE_API_URL + "/api/company/channel/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getChannelDetails(channelId) {
    let result = new GetChannelDetailsResult();
    fetch(window.MESSAGE_API_URL + "/api/company/channel/" + channelId + "/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  sendMessage(channelId, content) {
    let result = new SendMessageResult();
    fetch(window.MESSAGE_API_URL + "/api/company/channel/" + channelId + "/send_message/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify({ content: content }),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  saveDraftMessage(channelId, content) {
    let result = new SaveDraftMessageResult();
    fetch(window.MESSAGE_API_URL + "/api/company/channel/" + channelId + "/send_draft_message/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify({ content: content }),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getChannelId(jobRequestId, sponsoredLeadId) {
    let result = new GetChannelIdResult();
    fetch(window.MESSAGE_API_URL + "/api/channel/get_channel_id/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST",
      body: JSON.stringify({ jobRequestId: jobRequestId, sponsoredLeadId: sponsoredLeadId }),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  joinChannelForCompany(channelId) {
    let result = new JoinChannelForCompanyResult();
    fetch(window.MESSAGE_API_URL + "/api/company/channel/" + channelId + "/join/", {
      headers: this.authService.getAuthorizationHeader(),
      method: "POST"
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

}
