import {
  CoinsLeaderBoard,
  UserProfileResult,
  UserProfilesData,
  UserRegistration,
  UserProfileChangeResult,
  ApplyForBeingACreatorResult,
  MultiplePublicUserProfileDataResult,
  PublicUserProfileResult,
} from "./UserServiceResponses";

export class UserServiceStub {
  constructor(auth) {
    this.authService = auth;
  }

  getUserProfile() {
    let result = new UserProfileResult();

    setTimeout(function() {
      result.loaded = true;
      result.user_profile = {
        display_name: "JuJu4tw",
        uuid: "87d45d2b-58c6-4bad-aed9-30c0d577204e",
        first_name: "Ju",
        last_name: "Sha",
        contact_email_address: "ju.sha@email.de",
        region: "DE-BE",
        region_choices: [
          { code: "DE-ST", region: "Sachsen-Anhalt" },
          { code: "DE-SN", region: "Sachsen" },
          { code: "DE-BE", region: "Berlin" },
        ],
        profession: "JU",
        profession_choices: [
          { code: "ST", profession: "Student" },
          { code: "JU", profession: "Junior Developer" },
          { code: "SE", profession: "Senior Developer" },
        ],
        profile_picture: "https://entwicklerheld.de/static/img/team_ilja_v.jpg",
        newsletter: false,
        data_privacy_confirmation: true,
        is_creator: false,
        encoded_region:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiJhdXRoMHw1YTk5NzM2YWFlOWY5YjVlZTVhY2RiZDgiLCJyZWdpb24iOiJERS1CRSJ9.eDg5bq5uC-QDNZyCg-Pz-dHTPmjWWRXjnhRCN3vofoc",
      };
    }, Math.random() * 1500);
    return result;
  }

  changeUserProfile(data) {
    let result = new UserProfileChangeResult();

    setTimeout(function() {
      result.loaded = true;
      result.error = null;
    }, Math.random() * 1500);

    return result;
  }

  getProfileDataOfMultipleUsers(user_ids) {
    let result = new UserProfilesData();

    setTimeout(function() {
      result.loaded = true;
      result.userProfilesData = {
        "google-oauth2|107982076963308370959": {
          region: "Sachsen",
          profession: "Junior-Developer",
        },
        "auth0|5c79045916ba4a2e84dc4265": {
          region: "Sachsen",
          profession: "Senior-Developer",
        },
        "auth0|5c8912b3880ea13b8e85412b": {
          region: "Sachsen",
          profession: "Senior-Developer",
        },
        "auth0|5c8e7a2a3cb7a74a5bd57a71": {
          region: "Sachsen",
          profession: "Student",
        },
      };
    }, Math.random() * 1500);

    return result;
  }

  postUserRegistration(data) {
    let result = new UserRegistration();

    setTimeout(function() {
      result.loaded = true;
      result.error = null;
    }, Math.random() * 1500);

    return result;
  }

  applyForBeingACreator(creatorInterest) {
    let result = new ApplyForBeingACreatorResult();
    setTimeout(function() {
      result.loaded = true;
      result.error = null;
    }, Math.random() * 1500);

    return result;
  }

  getCoinsLeaderBoard() {
    let result = new CoinsLeaderBoard();

    setTimeout(function() {
      result.leaderBoard = [
        {
          position: 1,
          public_user_id: "1",
          current_user: false,
          display_name: "Anonymous Fabulous Zero",
          profile_picture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
          total_coins_earned: 2000,
        },
        {
          position: 2,
          public_user_id: "2",
          current_user: false,
          display_name: "Ada Example",
          profile_picture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
          total_coins_earned: 1000,
        },
        {
          position: 3,
          public_user_id: "3",
          current_user: false,
          display_name: "Ada Example One",
          profile_picture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
          total_coins_earned: 1000,
        },
        {
          position: 4,
          public_user_id: "4",
          current_user: false,
          display_name: "Anonymous Fabulous One",
          profile_picture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
          total_coins_earned: 0,
        },
        {
          position: 5,
          public_user_id: "5",
          current_user: false,
          display_name: "Anonymous Fabulous Two",
          profile_picture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
          total_coins_earned: 0,
        },
        {
          position: 6,
          public_user_id: "6",
          current_user: false,
          display_name: "Anonymous Fabulous Three",
          profile_picture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
          total_coins_earned: 0,
        },
        {
          position: 20,
          public_user_id: "10",
          current_user: false,
          display_name: "Anonymous Fabulous Seven",
          profile_picture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
          total_coins_earned: 0,
        },
        {
          position: 21,
          public_user_id: "11",
          current_user: false,
          display_name: "Anonymous Fabulous Eight",
          profile_picture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
          total_coins_earned: 0,
        },
        {
          position: 22,
          public_user_id: "13",
          current_user: true,
          display_name: "Held",
          profile_picture: "https://entwicklerheld.de/static/img/team_ilja_v.jpg",
          total_coins_earned: 0,
        },
        {
          position: 23,
          public_user_id: "12",
          current_user: false,
          display_name: "Anonymous Fabulous Nine",
          profile_picture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
          total_coins_earned: 0,
        },
      ];
      result.error = null;
      result.loaded = true;
    }, Math.random() * 1500);

    return result;
  }

  getPublicUserProfile(userId) {
    let result = new PublicUserProfileResult();

    setTimeout(function() {
      result.apiData = {
        uuid: "cbff9719-8b35-4b6a-a7ff-f4cc0486e6fa",
        profilePicture: "https://entwicklerheld.de/static/img/team_ilja_v.jpg",
        displayName: "Ilja Boar",
        isCreator: true,
      };
      result.error = null;
      result.loaded = true;
    }, Math.random() * 1500);

    return result;
  }

  getDataForPublicUserIds(userIds) {
    let result = new MultiplePublicUserProfileDataResult();

    setTimeout(function() {
      result.apiData = [
        {
          publicUserId: "6296f40d95c3fdeb12216d525f44f36c",
          displayName: "Friedemannwulfwulfwulsten",
          profilePicture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
        },
        {
          publicUserId: "6296f40d95c3fdeb12216d525f44f36e",
          displayName: "Jakob",
          profilePicture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
        },
        {
          publicUserId: "6296f40d95c3fdeb12216d525f44f36d",
          displayName: "Felix",
          profilePicture:
            "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5c483da5137421218b5983fd/b28f8b53-8942-4fe3-b739-a998ad99f749/128?size=48&s=48",
        },
        {
          publicUserId: "4",
          displayName: "Ronny",
          profilePicture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
        },
        {
          publicUserId: "5",
          displayName: "Pi",
          profilePicture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
        },
        {
          publicUserId: "6",
          displayName: "Ronny",
          profilePicture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
        },
        {
          publicUserId: "7",
          displayName: "Norma",
          profilePicture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
        },
        {
          publicUserId: "8",
          displayName: "Steffi",
          profilePicture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
        },
        {
          publicUserId: "9",
          displayName: "Mirko",
          profilePicture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
        },
        {
          publicUserId: "10",
          displayName: "Benjamin",
          profilePicture: "https://s3.amazonaws.com/s3-cuescience/anonym-user.png",
        },
      ];
      result.error = null;
      result.loaded = true;
    }, Math.random() * 1500);

    return result;
  }
}
