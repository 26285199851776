import { responseHandler } from "../Utilities.js";
import { HistoryReport, QualityReport, Scores } from "./QualityServiceResponses";

export class QualityServiceNetwork {
  constructor(auth) {
    this.authService = auth;
  }

  getQualityReport(challengeId, userId) {
    let result = new QualityReport();
    fetch(window.QUALITY_API_URL + "/api/scores/challenge/" + challengeId + "/score/" + userId + "/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getQualityReportByUuid(acceptedChallengeUuid) {
    let result = new QualityReport();
    fetch(window.QUALITY_API_URL + "/api/scores/" + acceptedChallengeUuid + "/score/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getMeasures(challengeId, userId) {
    let result = new QualityReport();
    fetch(window.QUALITY_API_URL + "/api/scores/challenge/" + challengeId + "/measurements/" + userId + "/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getMeasuresByUuid(acceptedChallengeUuid) {
    let result = new QualityReport();
    fetch(window.QUALITY_API_URL + "/api/scores/" + acceptedChallengeUuid + "/measurements/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getScores(challengeId) {
    let result = new Scores();
    fetch(window.QUALITY_API_URL + "/api/scores/challenge/" + challengeId + "/scores/", {
      headers: this.authService.getAuthorizationHeader(),
    })
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        console.error(error);
        result.loaded = true;
        result.error = error;
      });
    return result;
  }

  getHistoryReport(challengeId, userId, file, task) {
    let result = new HistoryReport();
    fetch(
      window.QUALITY_API_URL + "/api/report/" + challengeId + "/history/" + userId + "/?file=" + file + "&task=" + task,
      {
        headers: this.authService.getAuthorizationHeader(),
      }
    )
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        result.error = error;
        result.loaded = true;
        console.error(error);
      });
    return result;
  }

  getHistoryReportByUuid(acceptedChallengeUuid, file, task) {
    let result = new HistoryReport();
    fetch(
      window.QUALITY_API_URL +
        "/api/history/accepted_challenge/" +
        acceptedChallengeUuid +
        "/?file=" +
        file +
        "&task=" +
        task,
      {
        headers: this.authService.getAuthorizationHeader(),
      }
    )
      .then(responseHandler)
      .then((json) => {
        result.apiData = json.data;
        result.loaded = true;
      })
      .catch((error) => {
        result.error = error;
        result.loaded = true;
        console.error(error);
      });
    return result;
  }
}
